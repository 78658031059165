import React from "react"
import { withRouter } from "react-router";
import { uniqueId } from "../../../utils/Constants";


export type BaseProps = {

    label?:string | React.Component,
    required?: boolean,
    requiredmessage?: string,
    validations?: any[],
    formfield?: string,
   
}

export type FieldProps = {
    name: string,
    label?:string | React.Component,
    required?: boolean,
    requiredmessage?: string,
    validations?: any[],
    formfield?: string,
    onChange? :(value:any)=>void
}

export const defaultFieldProps  = {
 
    required:false,
    requiredmessage: "Este campo es requerido",
    formfield:"true"
}


export type FieldStateType = {
    value:any,
    error:string | null
}


export class BaseField<P={}, S={}> extends React.Component<FieldProps & P, FieldStateType & S> {
    
    static defaultProps = defaultFieldProps;
    id = uniqueId("field")

    
    constructor(props: any) {
        super(props);
        this.state  = {
            value: props.defaultValue ?  props.defaultValue : null,
            error: null
        } as FieldStateType & S;

    
    }

    getValue(){
        return this.state.value
    }

    setValue = (value:any) => {
        console.log(value)
      this.setState({value})
    }

    displayError = (message:any) =>{
        this.setState({error:message})
    }

    isRequired = () =>{
        return this.props.required
    }

    getValidations = () =>{
        return this.props.validations || []
    }
   

}


