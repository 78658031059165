
import classNames from "classnames"
import React, {FunctionComponent} from "react"


type CardProps = {
  className?: string,
  bodyClassName?: string,
  id?: string
}

const defaultProps : CardProps = {
  className:"",
  bodyClassName:""
}


export const Card : FunctionComponent<CardProps> = (props) =>{

    const {id, className,bodyClassName } = props


  return (
    <div id={id} className={`card ${classNames(className)}`}>
      <div className={`card-body ${classNames(bodyClassName)}`}>
        {props.children}
      </div>
    </div>
  )
  

}

Card.defaultProps = defaultProps;

