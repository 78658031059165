import React, {createRef, FunctionComponent, useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, bootstrap, Button, Table } from "../lfit-lib"
import { Avatar } from "../lfit-lib/Components/Avatar"
import { Modal } from "../lfit-lib/Components/Modal"
import { Popover } from "../lfit-lib/Components/Popover"
import { Toast } from "../lfit-lib/Components/Toast"
import Input from "../lfit-lib/Forms/Fields/Input"
import Radio from "../lfit-lib/Forms/Fields/Radio"
import Select from "../lfit-lib/Forms/Fields/Select"
import Form from "../lfit-lib/Forms/Form"
import { toggleToast } from "../store/actions"
import { API } from "../utils/APIHandler"
import { getIMC, IMC, PRODUCT_STATUS, PROFILE_TYPES, STATUS } from "../utils/Constants"
import { Loader } from "../utils/Loader"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import { Carousel } from "../lfit-lib/Components/Carousel"
import { PlanSelectorModal } from "../components/PlanSelector"



type CustomerParams = {
  id: string;
};


export const Customer : FunctionComponent = (props) =>{
  const userS = useSelector((state:any)=>state.user.user)
  console.log(userS)
  let id = userS.shopify_id
  const [loading, setLoading] = useState(false)

  const [disease, setDisease] = useState(false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [suplement, setSuplements] = useState(false)
  const [disabledFH, setDisabledFH] = useState(false)
  
  const [hf, setHF] = useState<any>([])
  const [user, setUser] = useState<any>({state:"disabled"})
  const form = useRef <any> ()
  const formFH = useRef <any> ()
  const dispatch = useDispatch()
  const modal = useRef<any>(null)
  const planSelector = useRef<any>(null)
  const popovers :any = {};
  const [planList, setPlanList] = useState<any>([])
  const [plans, setPlans] = useState([])

  const [orders, setOrders] = useState([])
  const [pagination, setPagination] = useState({prev:"", next:"",prev_cur:"", next_cur:""})


 




  
  useEffect(()=>{


    setLoading(true)

    API.get(`/customer/get/${id}`,(response)=>{
      //console.log(response)
      setUser(response.data)
      //console.log(form)
      form.current.fill(response.data)

      setLoading(false)

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      new bootstrap.Popover(tooltipTriggerEl, {animation:false,html: true,})
  
    })

    },(response)=>{
      //console.log(response)
      setLoading(false)
    })


    


    
  },[id])

  const updateUser = (data:any, valid:boolean) => {

    if (valid){
      setLoading(true)

      API.post(`/customer/set/${id}`, data,(response)=>{
        //console.log(response)
        setUser(response.data)
        setLoading(false)
        console.log(response)

        Toast.add({message:"Información de usuario actualizada", type:"primary"})

      },(response)=>{
        //console.log(response)
        setLoading(false)

      })
    }
  }





  

  const profileCard = () => {
    return (
    <div>


      <div className={"p-4"}>
      <div className={"d-flex flex-column align-items-center"}>

        <div className={""}><Avatar size={7} fontSize={5} initials={(user.full_name || "-").toUpperCase()[0]} img={user.img}/></div>
        <div className={"mt-2 text-center"}>
          <div className="fs-4 text-capitalize">{user.full_name}</div>
          <div className="fs-6">{user.email}</div>
          <div className="text-capitalize mt-1">({PROFILE_TYPES[user.profile]})</div>
        </div>

        
      </div>
  
      {/*<Button block size={"sm"} className={"mt-3"} color={"outline-secondary"}>Actualizar imagen</Button>*/} 

  

      <hr/>

      <div className={"row text-center"}>

        <div className={"col-6"}>
          <div className={"fw-bold"}>Fecha de creación</div>
          <div>{user.member_since}</div>
        </div>
        <div className={"col-6"}>
          <div className={"fw-bold"}>Última actualización</div>
          <div>{user.updated_at}</div>

        </div>

        
      
      </div>
      </div>
    
  

 
      </div>
    )
  }



  const profileTab = () =>{


    return (
<React.Fragment >

<Card  className={"elevated mb-3"} bodyClassName={"p-0"}>

<div className={"bg-light p-2 px-3 fs-6 text-gray fw-normal border-bottom mb-2"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
Información de usuario
  </div>

  <div className={"p-4 px-4 pt-0"}>



  <Form key={"profileTab"} ref={form} onSubmit={updateUser}>
  <div className={"row"}>
      <Input className={"col-12 mt-2"} disabled name={"email"} placeholder={"Correo electrónico"}/>

    </div>
    <div className={"row"}>
       <Input className={"col-12 col-lg-6 mt-2"} name={"first_name"} placeholder={"Nombre"}/>
       <Input className={"col-12 col-lg-6 mt-2"} name={"last_name"} placeholder={"Apellidos"}/>

    </div>

    <div className={"row "}>
       <Input className={"col-12 mt-2"} name={"phone"} placeholder={"Teléfono"}/>
       

    </div>

    <div className={"row"}>
    <Input className={"col-12 col-lg-6 mt-2"} type={"date"} name={"birthdate"} placeholder={"Fecha de nacimiento"}/>

      <Radio buttonColor={"outline-secondary"} className={"col-12 col-lg-6 mt-2"} radioType={"Button"} label={"Género"} name={"gender"} options={[{label:"Mujer", value:"F"},{label:"Hombre", value:"M"}]}/>

    </div>
   

  

  
    <Button type={"submit"} color={"primary"} className={"mt-2"}  block>Actualizar</Button>



    
  

  </Form>

  </div>
 
</Card>





</React.Fragment>
    )
  }


  
  
  



  return (
      <React.Fragment>
        {loading? <Loader message={"Cargando..."}/>:null}
      


        <div className={"container"}>
       

          <div className={"row"}>
            <div className={"col col-4 d-none d-lg-block"}>
              <Card className={"elevated"} bodyClassName={"p-0"}>
              <div className="p-0 ">
                {profileCard()}
                </div>
              </Card>

      


            </div>
            <div className={"col col-12 col-lg-8"}>


              <div className={"d-lg-none"}>



              <Card className={"elevated mb-3"} bodyClassName={"p-0"}>

                



  
              {profileCard()}

              </Card>
              </div>

              <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                {profileTab()}
              </div>
          
            </div>



              </div>
          </div>

    
    
        </div>
        
    </React.Fragment>)
  

}
