


export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const toggleSidebar = toggle => {
    return {
      type: TOGGLE_SIDEBAR,
      payload: toggle,
    }
  }
  
  export const SET_BANNER_COLOR = "SET_BANNER_COLOR"
  export const setBannerColor = color => {
      return {
        type: SET_BANNER_COLOR,
        payload: color,
      }
    }

    export const resetBanner = () => {
        return {
          type: SET_BANNER_COLOR,
          payload: "#58bb43",
        }
      }
    


  export const TOGGLE_TOAST = "TOGGLE_TOAST"
  export const toggleToast = (toggle, toastMessage="") => {
      return {
        type: TOGGLE_TOAST,
        payload: {toggle, toastMessage}
      }
    }
    
  