import { Toast } from "../Components/Toast";

  export const getValue = (o: any , key:string) =>{
    key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    key = key.replace(/^\./, '');           // strip a leading dot
    var a = key.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
  }

  export const copyStringToClipboard = (str:any) => {
    // Create new element
    
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    Toast.add({message:"Link copiado!", type:"success"})
    
    // Remove temporary element
    document.body.removeChild(el);
 }