import React, {  useRef,forwardRef, useImperativeHandle, createRef, useEffect, FunctionComponent, ForwardRefRenderFunction, ReactPropTypes, PropsWithChildren } from "react"



const VALID_FIELDS : string[] = [
    "Input",
]

const RULES : string[] = [

]



export type FormProps = {
    initialValues?: {} ,
    className?:string,
    onSubmit?: (data:{}, valid:boolean)=>boolean |void
}
export type FormHandle = {
    fill: (values:any) => void,
    submit: () => any,
    reset: () => any,
}

export type FormType = ForwardRefRenderFunction<FormHandle, PropsWithChildren<FormProps>>
export const FormC : ForwardRefRenderFunction<FormHandle, PropsWithChildren<FormProps>> = (props, ref) => {



    let form = useRef<HTMLFormElement>(null)
    let fields :any = {};

    useEffect(()=>{
        fill(props.initialValues)

        },[props.initialValues])


    const field = (field :any, key:any) => {
        ////console.log(field)
        if (field && field.type){
            ////console.log(field)
            const FieldComponent = field.type
           
            if (field.props && field.props.name && field.props.formfield === "true"){
                
                fields[field.props.name] = createRef()
                //console.log(fields[field.props.name])
        
            }

            return <FieldComponent ref={fields[field.props.name]} key={key} {...field.props}/>
        }else{
 
            return field
        }
    }

    
    
    const layout :(arr:any, key:any) =>any = (arr:any, key:any) =>{


        if (arr && Array.isArray(arr)){
            return <>
                    {arr.map((element, id)=>{
                        return layout(element, id)
                    }) } </> 
    
        }else if (arr && arr.props && arr.props.children && Array.isArray(arr.props.children)){
           
                ////console.log(arr.type.name)
                let Wrapper = arr.type
                return <Wrapper key={key} {...arr.props}>
                    {arr.props.children.map((element:any, id:any)=>{
                        return layout(element, id)
                    }) } </Wrapper>  
      
    
        } else if (arr && arr.props && arr.props.children){
             
                let Wrapper = arr.type
         
                if (arr.props && arr.props.name && arr.props.formfield === "true"){
                    fields[arr.props.name] = createRef()
                }

                return <Wrapper key={key}  {...arr.props}>
                    {[arr.props.children].map((element, id)=>{

                        if (element && element.type){
                            if (!VALID_FIELDS.includes(element.type.name)){
                                
                                return layout(element, id)
                            }
                        }

                        return field(element, id)


                    }) } </Wrapper>  
    
        }else{
            return field(arr, key)
    
        }


    }    


    const fill = (data:any) => {
        //console.log(fields, data)
        if (data){
            let keys = Object.keys(data)
        
            for(let i = 0; i < keys.length; i++){
                //console.log(keys[i], fields[keys[i]])
                if (fields[keys[i]]){
                    //console.log(keys[i], fields[keys[i]])
                    fields[keys[i]].current.setValue(data[keys[i]] || "")
                }
            }

            
        }

        return  null

    }

    const reset = () =>{
        let keys = Object.keys(fields)
        for(let i = 0; i < keys.length; i++){
            fields[keys[i]].current.setValue(null)
        }
    }
    
    const validateData  = (name:string, value:any, field:any) => {

        if (field.isRequired()){
            ////console.log(name, value, field)
            if (value === undefined  || value === null || value === ""){
               
                field.displayError(field.props.requiredmessage)
                return false
            }
        }

        if (field.getValidations()){
            let validations = field.getValidations()

            for(let i = 0; i < validations.length; i++){
                let validation = validations[i]
                let v = validation(value)
                console.log(v)
                if (!v.valid){
                    field.displayError(v.message )
                    return false
                }
                

              

            }

        }

        return true
    }

    const handleSubmit = (event:any) => {
        event.preventDefault();
        //.log(fields)

        let data : any = {}
        let validData = true;
        let keys = Object.keys(fields)

        for(let i = 0; i < keys.length; i++){
            data[keys[i]] = fields[keys[i]].current.getValue();
            fields[keys[i]].current.displayError(false);
            validData = (validateData(keys[i], data[keys[i]], fields[keys[i]].current) && validData);
            ////console.log(keys[i])
        }
        ////console.log(data)

        if (props.onSubmit){
            props.onSubmit(data, validData)
        }

        return {data:data, validData:validData}
    }

    const submit = () => {
        ////console.log(form)
        return handleSubmit(new Event("submit"));
    }

    useImperativeHandle(ref, () => ({
        fill,
        submit,
        reset
    }));

    ////console.log(props.children)
    //layout(props.children)

    ////console.log(fields)
    return (
        <form noValidate className={props.className} ref={form} onSubmit={handleSubmit} onReset={reset}>
            {props.children ? layout(props.children, 0) : null}
  
        </form>
     
    )
    

}

const Form = forwardRef(FormC)
export default Form