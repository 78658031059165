
import classNames from "classnames"
import React, {FunctionComponent} from "react"


type AvatarProps = {
    initials? : string,
    color? : string,
    img? : string,
    size? : "xs" | "sm" | "md" | "lg" | Number,
    fontSize? : Number,
    className?: string
}

const defaultProps : AvatarProps = {
    initials: '-',
    size: 'xs',
    color: "primary",

}


export const Avatar : FunctionComponent<AvatarProps> = (props) =>{

    const {initials, img, size, color, className, fontSize} = props
    const innerStyle : React.CSSProperties | undefined = {}

    if(typeof size  == "number"){
        innerStyle.width = size + "rem"
        innerStyle.height = size + "rem"
    }

    if (fontSize){
        innerStyle.fontSize = fontSize + "rem"
    }

    //console.log(innerStyle)
    return (
        <div>


            {img ? <img src={img} className={`img-fluid ${classNames(className)}`} alt={"Avatar"}/> : 
                initials ? <div style={{backgroundColor:color, ...innerStyle}} className={`avatar-title avatar-${size} rounded-circle bg-${color} fw-bold ${classNames(className)}`}>{initials}</div> : 
                    null
            }
            
        </div>)
    

}

Avatar.defaultProps = defaultProps;

