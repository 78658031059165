
import classNames from "classnames"
import React, {FunctionComponent, useEffect, useImperativeHandle} from "react"
import { bootstrap } from ".."
import { uniqueId } from "../../utils/Constants"
import ReactDOM from 'react-dom';

type PopoverProps = {
  title?: JSX.Element | string,
  content?: JSX.Element, 
  hover?: Boolean,
  click?: Boolean
}

type PopoverHandler = {
  hide: () => void,
  show: () => void,
}

const defaultProps : PopoverProps = {
  hover:true
}

const PopoverWrapper : React.ForwardRefRenderFunction<PopoverHandler, React.PropsWithChildren<PopoverProps >>  = (props, ref) =>{

    const {title,content, hover, click } = props

    const id = uniqueId("popover")
  

    const hide = ()=>{

      try {
        var myModalEl :any = document.getElementById(id)
        var modal = bootstrap.Popover.getInstance(myModalEl) 
       modal!.update()
  
  
        modal!.show()
        modal!.hide()
      } catch (error) {
        console.log(error)
      }
  
    }


    const show = ()=>{

      try {
        var myModalEl :any = document.getElementById(id)
        var modal = bootstrap.Popover.getInstance(myModalEl) 
       modal!.update()
  
  
        modal!.show()
     
      } catch (error) {
        console.log(error)
      }
  
    }


    useEffect(()=>{


  
        const contentDiv = document.createElement("div")

        ReactDOM.render(content!, contentDiv);
  
        const titleDiv = document.createElement("div")
    

        if (title){
          titleDiv.classList.add("popover-title")
          ReactDOM.render(<div><button type="button" onClick={hide} className="btn-close float-end" aria-label="Close"></button>{title!}</div>, titleDiv);
        }
    
    
        let popover: any = document.getElementById(id)
        var popoverCont: any = document.getElementById("cont"+id)

        let trigger: any = `${click? "click":""}${click && hover?" ": ""}${hover?"hover":""} `
        new bootstrap.Popover(popover, {
          animation:false,
          html: true,
          content:contentDiv,
          title:titleDiv,
          trigger: trigger,
          container:popoverCont
        })

      
  },[])

    

  useImperativeHandle(ref, () => ({
    hide,
    show
    }));



  return (
    <span style={{cursor:"pointer"}} id={id} data-bs-custom-class={"shadow-lg"} tabIndex={0}  
                      data-bs-toggle="popover" 
                      data-bs-placement="top">

        <div id={"cont"+id}></div>
        {props.children}
    </span>
    
  )
  

}

export const Popover = React.forwardRef(PopoverWrapper)
Popover.defaultProps = defaultProps;