export const  Loader = ({message}) => {
    return (
      <div id="preloader" style={{display:"block"}}>
      <div id="status" style={{display:"block"}}>
        <div className="spinner-chase">
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
        </div>
      </div>
      <div className={"text-center"} style={{top:"50%", position:"absolute", marginTop:50, width:"100%"}}>{message}</div>

    </div>
    )
  }