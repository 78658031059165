import React, { FunctionComponent, useRef, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Button } from "../lfit-lib"
import Input from "../lfit-lib/Forms/Fields/Input"
import Form from "../lfit-lib/Forms/Form"
import { login } from "../store/actions"
import bgLogin from "./../assets/images/bg-login.jpeg"
import logo from "./../assets/images/logo-vertical.png"

export type LoginProps = {}

export const Login: FunctionComponent<LoginProps> = (props) => {

    const form = useRef<any>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const [redirect, setRedirect] = useState("/home")

    useEffect(() => {
        const queryString = window.location.search;
        ////console.log(queryString);
        const urlParams = new URLSearchParams(queryString);

        //console.log(redirect)

        setRedirect(urlParams.get('rediretTo') || "/home")

    }, [])

    const callLogin = (data: any, valid: boolean) => {
        console.log(data, valid)
        setError(false)
        if (valid) {
            setLoading(true)
            dispatch(login({
                data: data,
                onSuccess: (response) => {
                    console.log(response)
                    window.location.href = redirect
                    setLoading(false)

                },
                onError: (error) => {
                    console.log(error)
                    setError(error)
                    setLoading(false)

                }
            }))
        }

    }

    return (
        <React.Fragment>
            <div className="row m-0">
                <div className="col-lg-6 col-md-12 p-0">
                    <div style={{ height: "100%", width: "100%", backgroundImage: `url(${bgLogin})`, backgroundPosition: "left", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} >

                    </div>
                </div>
                <div className="col-lg-6 col-md-12 p-0 bg-white">
                    <div className="login-content">
                        <div className={"d-flex vh-100 justify-content-center"}>
                            <div className="align-self-center">
                                <div className="login-form">

                                    <div className={" mb-4"}>
                                        <img src={logo} width={350} className={"img-fluid"} />

                                    </div>

                                    <h3>¡Bienvenido!</h3>
                                    <p className="">Zona Fit</p>
                                    <Form ref={form} onSubmit={callLogin}>
                                        <div className=" mb-3">
                                            <Input required name={"email"} placeholder="Correo" />
                                        </div>
                                        <div className="mb-3">
                                            <Input required type="password" placeholder="Contraseña" name={"password"} />
                                        </div>

                                        <div className={`d-flex justify-content-center mb-3 text-danger ${error ? "" : "d-none"}`}>
                                            {error}
                                        </div>


                                        <div className="d-flex justify-content-center mb-3">
                                            <Button
                                                size={"lg"}
                                                loading={loading}
                                                onClick={() => {
                                                    form.current.submit()
                                                }}
                                                type={"submit"}
                                                color={"primary"}>Iniciar sesión
                                            </Button>
                                        </div>

                                        <div className=" d-flex justify-content-center mb-3">

                                            <a href="https://www.librementefit.mx/account/login?return_url=%2Faccount#recover" target={"_blank"}>¿Olvidaste tu contraseña?</a>

                                        </div>


                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )

}



/* <div className={"d-flex vh-100 justify-content-center"}>
                <div className="align-self-center">
                    <div className={"shadow-lg card"}>
                        <div className={"card-body mx-4"}>
                            <img src={logo} width={250} className={"img-fluid"}/>
                            <Form ref={form} onSubmit={(data, valid) => console.log(data, valid)}>
                                <Input name={"ss"}/>

                                <div className={"d-flex justify-content-center"}>
                                    <Button
                                        onClick={() => {
                                        console.log(form.current.submit())
                                    }}
                                        type={"submit"}
                                        color={"primary"}>Iniciar sesión</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

            </div> */
