import React, {FunctionComponent, ReactNode} from "react"
import { Redirect } from "react-router"
import { Customer } from "../pages/Customer"
import { Customers } from "../pages/Customers"
import { FitnessAndHealth } from "../pages/FitnessAndHealth"
// import { Home } from "../pages/Home"
import { Login } from "../pages/Login"
import { MisCompras } from "../pages/MisCompras"
import { Orders } from "../pages/Orders"
import { Plan } from "../pages/Plan"
import { Plans } from "../pages/Plans"
import { PlanView } from "../pages/PlanView"
import { Products } from "../pages/Products"
import { ProductView } from "../pages/ProductView"
import { Settings } from "../pages/settings/Settings"


interface Route {
    path: string;
    exact?: boolean;
    component: React.Component | (()=>React.Component) | FunctionComponent | ReactNode;
}


const authRoutes : Route[] =  [
    {path:"/home", exact:true, component: ()=><Redirect to={"/plans"} />},

  
    {path:"/customers", exact:true, component: Customers},


    {path:"/plans", exact:true, component: Plans},
    {path:"/plans/view/:id", exact:true, component: PlanView},

    {path:"/plans/edit/:id", exact:true, component: Plan},


    {path:"/products", exact:true, component: Products},
    {path:"/products/edit/:id", exact:true, component: ProductView},
    {path:"/mi-cuenta", exact:true, component: Customer},
    {path:"/fitness-health", exact:true, component: FitnessAndHealth},
    {path:"/compras", exact:true, component: MisCompras}

]

const publicRoutes  : Route[] = [
    {path:"/login", exact:true, component: Login},
    {path:"/", exact:true, component: ()=><Redirect to={"/login"} />},
]

export {authRoutes, publicRoutes }
