import React, { useEffect, useState } from "react"
import { Redirect, Route, useHistory, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useLocation } from 'react-router-dom'
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { getProfile, login, setProfile } from "../../store/actions"





const RoutesMiddleware = (props: any) => {
  const { component: Component, layout: Layout, loader: Loader, routes, authRequired  } = props
  const location = useLocation()
  const history = useHistory()

  const [authSuccess, setAuthSuccess] = useState(false)

  const dispatch = useDispatch()

  useEffect(()=>{
    
    if (authRequired){
      console.log("authRequired")
      dispatch(getProfile((response:any)=>{
        if (response.status == 1){
          console.log(response)
          dispatch(setProfile(response.data.user))
          setAuthSuccess(true)
        }else{
          history.push("/login?rediretTo="+window.location.href)
        }
      },(error:any)=>{
        history.push("/login?rediretTo="+window.location.href)
      }))
      console.log("authRequired2")
    }

  },[])

  if (authRequired){

        let AUTHLayout = authSuccess ? Layout : React.Fragment
        return ( 
          <AUTHLayout>
           
              <Switch location={location}>
                
                
                {routes.map((route: any, key:any)=>
                  {
                  let Component = route.component
                  return <Route key={route.path} path={route.path}  render={(props)=>{

                      if (!authSuccess){
                        return (<Loader/>)
                      }
          
                    
                      return  <Component  {...props}/>
                    
                  }}  exact={route.exact} />
                  }

                )}


              </Switch>

        </AUTHLayout>

      )
    }

  
  
  

  return (

       
             
                <Switch>
                  
                  
                  {routes.map((route: any, key:any)=>
                    {
                    let Component = route.component
                    return <Route key={route.path} path={route.path}  render={(props)=>{
                      return     <Layout><Component  {...props}/></Layout> 
                    }}  exact={route.exact} />
                    }

                  )}


                </Switch>
   

        )
      

}



RoutesMiddleware.propTypes = {
}
  

export default (RoutesMiddleware)



/*
              
                <TransitionGroup>
                <CSSTransition
                  timeout={250}
                  classNames='fade'
                
                  key={location.pathname} location={location}
                >
                <Switch>
                  
                  
                  {routes.map((route: any, key:any)=>
                    {
                    let Component = route.component
                    return <Route key={route.path} path={route.path}  render={(props)=>{
                      return     <Layout><Component  {...props}/></Layout> 
                    }}  exact={route.exact} />
                    }

                  )}


                </Switch>
              </CSSTransition>
          </TransitionGroup>
*/