
import React, {FunctionComponent} from "react"
import { Link } from "react-router-dom"
import { Color } from "../Utils/Colors"
import { Icon } from "../Utils/Icons"




type StatCardProps = {
    stat? : string,
    label? : string,
    color? : Color,
    iconClass? : Icon,
    preIconClass?: string,
    iconStyle?: React.CSSProperties | undefined
}

const defaultProps : StatCardProps = {
    stat: "99,999.00",
    label: '-',
    iconStyle:{}
}



export const StatCard : FunctionComponent<StatCardProps> = (props) =>{

    const {stat, label, color, iconClass, preIconClass, iconStyle} = props


  return (
    <div className="card elevated">
        <div className="card-body p-3 py-3">

        <div className={"d-flex"}>
            <div className={"d-flex align-items-center me-auto"}>
                <i className={`${preIconClass} me-1 fs-2`}/>
                
                <div  className={""}>
                    <div className="card-subtitle text-muted text-xs  fw-bold text-uppercase">{label}</div>
                    <h3 className="card-title">{stat}</h3>
                </div>


            </div>
            <div className={"ms-auto"}>
                <span className={`icon icon-shape bg-${color} shadow-lg`} style={{...iconStyle}}>
                <i className={`${iconClass} text-white`}/>
            </span>
    
            </div>

        </div>

        <div className={"text-end text-xs fw-light mt-1"}>
            <Link className={"text-decoration-none text-truncate"} to={"#"}>Ver más <i className={"fas fa-arrow-right"}/></Link>
        </div>
        </div>
    </div>

  )
  

}

StatCard.defaultProps = defaultProps;

