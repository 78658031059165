
import classNames from "classnames"
import React, {ForwardRefRenderFunction, forwardRef, PropsWithChildren, useState, useImperativeHandle} from "react"
import { ButtonColor } from "../../Utils/Colors"
import { BaseField, defaultFieldProps, FieldProps } from "./BaseField"

export type RadioProps = {
    options: {label?:string | React.ReactNode, value: string | number | any, color?:ButtonColor }[]
    radioType?: "Button" | "Radio",
    buttonColor? : ButtonColor,
}



class Radio extends BaseField<RadioProps & React.HTMLProps<HTMLButtonElement>> {

    onChange = (value:any) =>{

        console.log(value)

        if (value == 0 || (value != null && value != "")){
            this.setValue(value)
            this.props.onChange && this.props.onChange(value)
        }else{
            this.setValue(null)
            this.props.onChange && this.props.onChange(null)
        }

       

    }
   

    render(){
        const { options, name, className, label, radioType, buttonColor="outline-primary" } = this.props
        const { error } = this.state

        let radioClassType = (radioType == "Button" ? "btn-check" : "form-check-input")
        let labelRadioClassType = (radioType == "Button" ? `btn btn-sm btn-${buttonColor} w-100` : "form-check-label")


        return (
            <div className={`${className}`}>
               <div>
               <label htmlFor={name} className="form-label mb-0">{label}</label>
               </div>
               
                
                <div className={`${radioType == "Button" ? "row g-1" :""}`}>
                    {options.map((option, index)=>
                        <div key={index} className={`${radioType == "Button" ? "col" :"form-check "}`}>
                            <input onClick={()=>this.onChange(option.value)} className={`${radioClassType}`} type="radio" name={name} autoComplete="off" checked={this.state.value == option.value} id={`${name}${index}`}/>
                            <label className={`${labelRadioClassType} ${option.color ? " btn-"+option.color:""}`} htmlFor={`${name}${index}`}>
                                {option.label || option.value}
                            </label>
                        </div>
                    )}
                </div>

                <div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
            </div>
        )
    }
}


export default Radio