
import classNames from "classnames"
import React, {FunctionComponent, ForwardRefRenderFunction, useEffect, useState, useImperativeHandle, useRef, createRef, PropsWithChildren} from "react"
import { uniqueId } from "../../utils/Constants"
import { Color } from "../Utils/Colors"


type ToastType = {

  message?: string, 
  type?: "primary"|"success" | "warning" | "danger" | "info"
}


export class Toast{

  static ToastContainerRef = createRef<any >()

  static add(toast:ToastType){
    console.log(toast)
    Toast.ToastContainerRef.current.add(toast)

  }

}


type ToastProps = {

}
type ToastHandle = {
  add: (toast:ToastType) => void,
}

const ToastWrapperComponent: ForwardRefRenderFunction<ToastHandle, PropsWithChildren<ToastProps>> = (props, ref) =>{

    const [toastToDisplay, setToast] = useState<ToastType[] >([])

    const  getIcon = (type:any) => {
      switch (type) {
          case "success":  
            return "check-circle";
          case "primary":  
            return "check-circle";
          case "warning": 
            return "exclamation-circle";
          case "danger": 
            return "times-circle";

          case "info": 
            return "info-circle"; 
          default: 
            return "ff";
      }
  }

    useImperativeHandle(ref, () => ({
      add:(toast)=>{
        //const toats = JSON.parse(JSON.stringify(toastToDisplay)); 
        //toats.push(toast)
        //setToast(toats)
        const id = uniqueId("toast")
        let htmlString = `

          <div class="d-flex">
            <div class="toast-body">
              <div class="d-flex align-items-center">
                <i class="fas fa-${getIcon(toast.type)} me-2 fs-4"></i> 
                <div>${toast.message}</div>
              </div>
            </div>
            <button type="button" onclick="var e = document.getElementById('${id}'); e.remove();" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="${id}" aria-label="Close"></button>
          </div>

        `
        setTimeout(function(){
        var e = document.getElementById(id); 
        e?.classList.remove("opacity-0");
        e?.classList.add("opacity-100");
          setTimeout(function(){
            try {
              var e = document.getElementById(id); 
              e?.classList.remove("opacity-100");
              e?.classList.add("opacity-0");
              //e?.remove();
              setTimeout(function(){
                //e?.remove();
              },1000);
            } catch (error) {
              
            }
          },5000);
        },100);


        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        div.id = id
        div.setAttribute("class", `toast align-items-center text-white bg-${toast.type} border-0 fade show opacity-0 shadow-lg`);
        div.setAttribute("aria-live", "assertive");
        div.setAttribute("aria-atomic", "true");
        div.setAttribute("role", "alert");
        let E = document.getElementById("uniqueToastWrapper")
        E?.append(div)



      }
   
      }));



  return (
    <div id={"uniqueToastWrapper"} className="position-fixed bottom-0 p-3 toast-container end-0" style={{ zIndex: 11 }}>


    
       
    </div>
  )
  

}
const ToastWrapper = React.forwardRef(ToastWrapperComponent)
export const ToastContainer: FunctionComponent = (props) =>{

  return (
    <ToastWrapper  key={"uniqueToastWrapper"} ref={ Toast.ToastContainerRef} />
  )

}


