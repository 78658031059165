
import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import { useHistory, useParams } from "react-router"

import { Button, FormHandle } from "../lfit-lib"
import { Popover } from "../lfit-lib/Components/Popover"
import Input from "../lfit-lib/Forms/Fields/Input"
import RichTextEditor from "../lfit-lib/Forms/Fields/RichTextEditor"
import Select from "../lfit-lib/Forms/Fields/Select"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import Form from "../lfit-lib/Forms/Form"
import { copyStringToClipboard } from "../lfit-lib/Utils/Tools"
import { API } from "../utils/APIHandler"
import { PRODUCT_STATUS, URL_STORE } from "../utils/Constants"
import { Loader } from "../utils/Loader"






export const ProductView : FunctionComponent = (props) =>{
  const [loading, setLoading] = useState(false)
  const navigation = useHistory()
  const [product, setProduct] = useState({handle:"", title:"", id:"",status:"draft"})

  const { id } = useParams<any>();

  const form = useRef <FormHandle> (null)

  const getProduct = () => {
    setLoading(true)
    API.get(`/products/${id}`, (response)=>{
     console.log(response)
     //modal.current.toggle()

     if (response.data && response.data.variants && response.data.variants.length > 0){
       let variant = response.data.variants[0]
      response.data.inventory = variant.inventory_management &&  variant.inventory_policy == "deny" ? "deny" : "continue"
      response.data.requires_shipping = variant.requires_shipping

     }

     form.current?.fill(response.data)


     setProduct(response.data)
   
     setLoading(false)
   
   },(response)=>{
     console.log(response)
     //modal.current.toggle()
     navigation.replace("/products")
     setLoading(false)
   })
   
   
     
   
   }

   const deleteProduct = () => {
    setLoading(true)
    API.delete(`/products/${id}`, (response)=>{
     console.log(response)
     //modal.current.toggle()

      navigation.replace("/products")
   
     setLoading(false)
   
   },(response)=>{
     console.log(response)
     //modal.current.toggle()
   
     setLoading(false)
   })
   
   
     
   
   }
   
     useEffect(()=>{
       getProduct()
    
     },[])
   
     const saveProduct = (data:any, valid:boolean) => {
   
       if (valid){
   
       setLoading(true)
       API.upload(`/products/${id}`, {...data,data:data}, (response)=>{
         console.log(response)
        
   
         setLoading(false)
         getProduct()
      
       },(response)=>{
         console.log(response)
         //modal.current.toggle()
   
         setLoading(false)
       })
       }
      
     }
  let url = URL_STORE + "products/" + product.handle

  return (
      <React.Fragment>

{loading ? <Loader message={"Cargando... Esta operación puede tardar unos minutos"}/> : null}

        <div className={"container"}>

          <div className="card elevated card-body">

            <div className="d-flex align-items-start">
              <div className="flex-grow-1 h2 mb-3">
                {product.title}<br/>
                <i className="fs-5">#{product.id}</i> 
              </div>

              <div className={`badge bg-${PRODUCT_STATUS[product.status].color}`}><b><i className={PRODUCT_STATUS[product.status].iconClass}/>{" "}{PRODUCT_STATUS[product.status].text}</b></div>

            </div>

          <Popover 
            title={<div>Liga directa al producto</div>}
            content={<div>Este campo se genera automaticamente cuando se crea por primera vez el producto. Este valor no se puede cambiar para crear una liga nueva es necesario crear un producto nuevo.</div>}>
            <span>Liga al producto en la tienda <i className="fas fa-info-circle"/></span>
          </Popover>

        
<div className={"flex-fill d-flex  align-items-center "}>

<Button onClick={()=>{
 copyStringToClipboard(url)
}} className={"ms-0"} color={"link"}><i className={"fas fa-copy"}/></Button>

<a href={url } target={"_blank"} className={"ms-2 me-2"}><span id={"hrefPage"}>{url }</span> <i className={"fas fa-external-link-alt"}/></a>



</div>
<hr/>

        <Form  ref={form} onSubmit={saveProduct}>


       

<Input className={"col-12 mt-1"} required name={"title"} placeholder={"Nombre"}/>
<div className="row">
  <div  className={"col-4 mt-4"}>
  <Upload  showImage name="image" required  />
  </div>
  <RichTextEditor className={"col-8 mt-4"} required name={"body_html"} label={"Descripción"}/>
</div>
<div className="row">
  <Input className={"col-6 mt-1"} type="number" required name={"price"} placeholder={"Precio"}/>

  <Input required requiredmessage="Este campo es requerido. Si no hay un precio de comparación puedes asignar 0." className={"col-6 mt-1"} type="number"  name={"compare_at_price"} placeholder={"Precio de comparación"}/>

</div>
<hr/>
<p>Puedes modificar el estado del producto para controlar la visibilidad:</p>
<ul>
    <li>Activo: El producto esta disponible para visualizar y comprar en la tienda.</li>
    <li>Archivado: El producto esta ya no se encuentra visible ni disponible en la tienda</li>
    <li>Borrador: El producto se encuentra en edición, no estará visible en la tienda.</li>
</ul>

<Select options={[{value:"active", label:"Activo"}, {value:"archived", label:"Archivado"}, {value:"draft", label:"Borrador"}]} className={"col-12 mt-4"} required name={"status"} label={"Estado del producto"}/>
<Select options={[{value:"deny", label:"Sí"}, {value:"continue", label:"No"}]} className={"col-12 mt-4"} required name={"inventory"} label={"¿Producto agotado?"}/>
<Select options={[{value:"True", label:"Sí"}, {value:"False", label:"No"}]} className={"col-12 mt-4"} required name={"requires_shipping"} label={"Requiere envio?"}/>

  <div className="d-flex justify-content-end mt-2">
  <Button confirmationPopover onClickConfirmationPopoverOk={deleteProduct} confirmationPopoverHeader="¿Estas seguro que quieres eliminar este producto? Esta acción no se puede revertir." className="me-2" color="danger" ><i className="fas fa-trash"/> Eliminar</Button>

<Button color="primary" type="submit"><i className="fas fa-check"/> Actualizar</Button>
</div>
</Form>

     
</div>


    
      </div>
        
    </React.Fragment>)
  

}
