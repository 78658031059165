
import React, {FunctionComponent, useEffect, useState, useRef} from "react"
import { Button, Card } from "../lfit-lib"
import { Modal } from "../lfit-lib/Components/Modal"
import { API } from "../utils/APIHandler"
import  Form, {FormHandle}  from "../lfit-lib/Forms/Form"
import  Input  from "../lfit-lib/Forms/Fields/Input"
import TextArea from "../lfit-lib/Forms/Fields/TextArea"
import Select from "../lfit-lib/Forms/Fields/Select"
import { Link } from "react-router-dom"
import { useHistory } from "react-router"
import { Loader } from "../utils/Loader"
import { PRODUCT_STATUS } from "../utils/Constants"
import { Popover } from "../lfit-lib/Components/Popover"
import { useSelector } from "react-redux"




export type PlansProps = {
}



export const Plans : FunctionComponent<PlansProps> = (props) =>{
  const userS = useSelector((state:any)=>state.user.user)

  const [page, setPage] = useState(1)
  const [type, setType] = useState(null)
  const [size, setSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const [planTypes, setPlanTypes] = useState([])
  const form = useRef <FormHandle> (null)
  const modal = useRef <any> ()
  const navigation = useHistory()

const getPlans = () => {
  setLoading(true)
  API.get(
    `/plan/types`,
    (response)=>{
      setPlanTypes(response.data)
  
      API.get(
        `/plan/user/${userS.shopify_id}`,
        (response)=>{
          console.log(response)
          setPlans(response.data.items)

          setLoading(false)

        },
        (error)=>{
          setLoading(false)

        }
      )
    },
    (error)=>{
      setLoading(false)

    }
  )
  

}

  useEffect(()=>{
    getPlans()
 
  },[])

  


  return (
      <React.Fragment>
        {loading ? <Loader message={"Cargando..."}/> : null}


        <div className={"container-fluid"}>

 

          <div className="row text-white align-items-center px-2 mb-2">
              <div className="col"><h2 className="mb-0"><i className={"fas fa-clipboard-list"}/> Mis planes/retos</h2></div>

             
          </div>

          <div className={"row row-cols-1 row-cols-md-2 row-cols-xl-3"}>
          {/* <div className="col  pb-2">
          <a data-bs-dismiss="offcanvas" target="_blank"  href={"https://www.librementefit.mx/pages/faqs"} className={`nav-link mt-3 h5 text-center justify-content-center align-items-center`} aria-current="page">

                     <div  className="card h-100 elevated p-0">
                     <div style={{height:"100%"}} className={" border d-flex justify-content-center align-items-center bg-light card-img-top p-4 img-fluid"} >
          <i className={`flex-shrink-1 fas fa-question-circle fs-2 text- text-center`} /><br/>
          <div>Preguntas frecuentes</div>
   
                          </div>
                      </div>
                      </a>
                      </div> */}
            {plans.map((plan:any, index)=>{

              return (
                <div className="col  pb-2">
                     <div  className="card h-100 elevated p-0">


                 



                          {plan.images && plan.images.length > 0 ? <img src={plan.images[0].src} height={300} style={{objectFit:"contain"}} className=" card-img-top border"/> : 

                          <div style={{height:300}} className={" border d-flex justify-content-center align-items-center bg-light card-img-top p-4 img-fluid"} >
                            NO HAY IMAGEN
                          </div>
                          }

                        <div className="card-body d-flex flex-column">
                    

                      <h4 className="card-title">
                     
                      {plan.title}
                      <span style={{fontSize:16}} className={"badge bg-secondary float-end"}>{plan.plan_type}</span>

                      </h4>


              
                    
                      <div className={"d-flex justify-content-evenly align-items-stretch h-100"}>

                        <Link className={"float-end w-100 me-2 text-decoration-none"} to={`/plans/view/${plan.id}`}> <Button block className="h-100" color={"outline-secondary"}><i className={"fas fa-dumbbell"}/><br/>Ver mi plan</Button></Link>

                      </div>


                
                    </div>

                 
                  </div>
                </div>
              )

            })}


          </div>
       


     
      

    
      </div>
        
    </React.Fragment>)
  

}
