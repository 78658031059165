import React, {createRef, FunctionComponent, useEffect, useRef, useState} from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Card, bootstrap, Button } from "../lfit-lib"
import { Modal } from "../lfit-lib/Components/Modal"
import { Toast } from "../lfit-lib/Components/Toast"
import Input from "../lfit-lib/Forms/Fields/Input"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import Form from "../lfit-lib/Forms/Form"
import { resetBanner, setBannerColor, toggleToast } from "../store/actions"
import { API } from "../utils/APIHandler"
import { getIMC, IMC, PROFILE_TYPES, STATUS } from "../utils/Constants"
import { Loader } from "../utils/Loader"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: require('../assets/animation.json'),

};

type PlanParams = {
  id: string;
};

let uploadType = ""
let plan_version_id = ""
  
export const PlanView : FunctionComponent = (props) =>{

  const navigation = useHistory()
  const { id } = useParams<PlanParams>();
  const [loading, setLoading] = useState(false)
  const [url, setURL] = useState<any>({})


  const form = useRef<any>();
  const modalImage = useRef<any>();
  const formImage = useRef<any>();

  const modalUpload = useRef<any>();
  const formUpload = useRef<any>();

  const modalVersion = useRef<any>();
  const formVersion = useRef<any>();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [comments, setComments] = useState("");

  const [planTypes, setPlanTypes] = useState([])
  const [product, setProduct] = useState<any>({status:"draft", resources:[], versions:[]})
  const [version, setVersion] = useState<any>(null)

  const [plan, setPlan] = useState<any>({status:"draft", resources:[], versions:[]})
 
  const dispatch = useDispatch()


  const getData = () => {
    setLoading(true)
    API.get(
      `/plan/types`,
      (response)=>{
        setPlanTypes(response.data)
        API.get(`/plan/${id}/user`,(response)=>{
          console.log(response)
          if (response.data && response.data.plan){
            console.log(response)
            setProduct(JSON.parse(JSON.stringify(response.data )))
            setVersion(response.data.plan_version && response.data.plan_version.id ?  response.data.plan_version : null)
            setComments(response.data.comments)
            response.data = response.data.plan
           
            console.log(response.data)

            if ( response.data.resources ){
            response.data.resources = 
            response.data.resources.map((r:any, index:any)=>{
              
              r.display_order = index
              return r
            })

            if (response.data.resources.length > 0){
              setURL(response.data.resources[0])

            }
            setPlan(response.data)
          }
           
            setPageNumber(1)
            dispatch(setBannerColor(response.data.color))
            form.current.fill(response.data)
          }else{
            navigation.goBack()
          }
   

    

      setLoading(false)

      },(response)=>{
      //console.log(response)
      setLoading(false)
        })

      },
      (error)=>{

      }
    )

  }

  
  useEffect(()=>{
   


    getData()

    return ()=>{
      dispatch(resetBanner())
    }
  },[id])

  useEffect(()=>{
   


    setPageNumber(1)
  },[url.url])

  const updateGeneralInfo = (data:any, valid:boolean) => {
    if (valid){
      setLoading(true)
      API.put(`/plan/${id}`,
      data,()=>{getData()},()=>{})
    }

  }




 


  const profileCard = () => {
    return (
    <div>

  


      <div className={"p-4 row"}>



      <div className={"col-12 col-md-4 d-flex flex-column align-items-center"}>

        <div >

        {plan.image  ? 
        
        <img src={plan.image }  className="img-fluid rounded border" /> : 

            <div className={"w-100 border d-flex justify-content-center align-items-center bg-light rounded p-4"} style={{height:150}}>
              NO HAY IMAGEN
            </div>
          }


        </div>
     

        
      </div>
  

  

   

   

      <div className={"col-12 col-md-8 d-flex flex-column"}>
      <div className={""}>
          
          <div className="h3 text-capitalize" style={{color:plan.color}}>{product.title}</div>
          <div className="fs-5 text-capitalize mt-1">{plan.plan_type}</div>
        </div>

        <div className="h-100"  dangerouslySetInnerHTML={{ __html:plan.description  }}/>
          <div className="  d-flex justify-content-end mt-3">
        {plan.fb ?<a href={plan.fb} target={"_blank"} className=" btn btn-secondary d-flex justify-content-center align-items-center me-2"><i  className="fab fs-3 fa-facebook-square me-2"/> Grupo de Facebook</a>:null}
        
        {plan.wa ?<a href={plan.wa} target={"_blank"} className="btn btn-secondary d-flex justify-content-center align-items-center"><i className="fab fs-3 fa-whatsapp-square me-2"/> Grupo de WhatsApp</a>:null}
        </div>
      </div>
      </div>
   
 </div>
    )
  }




  const tabsSelect = (tabClass:string, buttonClass:string="", v:any={}) =>{

    return (
      <React.Fragment>

       

              
                {v  && v.menus && v.menus.sort((a:any,b:any)=>a.display_order - b.display_order).map((r:any,index:any)=>
        
        <li className={tabClass}>
            <button onClick={()=>setURL(r)} className={`nav-link  rounded-0  w-100 h-100 ${buttonClass}`}aria-current="page" 
            id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"  type="button" role="tab" aria-controls="profile" aria-selected="true"
            >{r.friendly_name}</button>
        </li>)}
        

        {v  && v.routines && v.routines.sort((a:any,b:any)=>a.display_order - b.display_order).map((r:any,index:any)=>
        
        <li className={tabClass}>
            <button onClick={()=>setURL(r)} className={`nav-link  rounded-0  w-100 h-100 ${buttonClass}`}aria-current="page" 
            id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"  type="button" role="tab" aria-controls="profile" aria-selected="true"
            >{r.friendly_name}</button>
        </li>)}

 

              
          
                
                  
                
      </React.Fragment>
    )
    
  }


  return (
      <React.Fragment>
        {loading? <Loader message={"Cargando..."}/>:null}

     
       
        <div className={"container"}>

        <Card className={"elevated mb-2"} bodyClassName={"p-0"}>
              <div className="p-0 ">

                {profileCard()}
                </div>
              </Card>
       

          <div className={"row"}>
            <div className={"col col-12 col-md-3"}>

            <Card  className={"elevated mt-2"} bodyClassName={"p-0 "}>
              
              <div className={"bg-light p-2 px-3 fs-6 fw-bold border-bottom"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
                Documentos generales
              </div>
              <ul className="nav nav-pills  flex-column">

            {plan  && plan.resources && plan.resources.sort((a:any,b:any)=>a.display_order - b.display_order).map((r:any,index:any)=>
        
        <li className={"nav-item"}>
            <button onClick={()=>setURL(r)} className={`nav-link  rounded-0  w-100 h-100 text-start`}aria-current="page" 
            id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"  type="button" role="tab" aria-controls="profile" aria-selected="true"
            >{r.friendly_name}</button>
        </li>)}
        </ul>
              </Card>

            
                <Card className={"elevated mt-2"} bodyClassName={"p-0 "}>
                

                <div className={"bg-light p-2 px-3 fs-6 fw-bold border-bottom"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
                  Tu plan
                </div>
                <ul className="nav nav-pills  flex-column">

                {version ?  <> {tabsSelect("nav-item", "text-start", version)}</>: <div className={"container text-center bg-white rounded elevated p-5 fs-4"}>
              <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                    isStopped={false}
                    isPaused={false}/>
                    Para poder preparar tu plan es necesario actualizar tus datos de <Link to="/fitness-health">Fitness & Health</Link>. Si ya lo hiciste no hagas caso a este mensaje.
      </div>
      }
              </ul>
              </Card>
        

    </div>

            <div className={"col col-12 col-md-9  mt-2"}>

            {comments ? <Card className={"elevated mb-2"} bodyClassName={"p-0"}>
              <div className={"bg-light p-2 px-3 fs-6 fw-bold border-bottom"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
                  Comentarios/observaciones
                </div>
                <div className="p-4">
             {comments}
             </div>
              </Card>:null}

              <Card className={"elevated mb-2"} bodyClassName={"p-0"}>
              <div className={"bg-light p-2 px-3 fs-6 fw-bold border-bottom"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
                  {url.friendly_name}
                </div>
            {url ? 
 <div className="p-2">

            <Document
      
              loading={<div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            </div>}
              file={url.url}
              onLoadSuccess={({ numPages }) =>{
                setNumPages(numPages);
              }}
            >
              <div className={"float-end"}>
              <a target={"_blank"} href={url.url  || ""} download>
              <Button color={plan.color}><i className={"fas fa-download"} /></Button>

         </a>
            

              </div>

              
              <div  className={"d-flex justify-content-center align-items-center"}>
                 <Button disabled={pageNumber - 1 < 1} onClick={()=>setPageNumber(pageNumber-1)} color={plan.color}><i className={"fas fa-chevron-left"} /></Button>
                <Page className={"img-fluid"} pageNumber={pageNumber} />
                <Button disabled={pageNumber + 1 > numPages} onClick={()=>setPageNumber(pageNumber+1)} color={plan.color}><i className={"fas fa-chevron-right"} /></Button>
              </div>
            </Document></div>
       
      :null}
              
              </Card>


              </div>
          </div>

    
    
        </div>
        
    </React.Fragment>)
  

}
