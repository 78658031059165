import {APICallResponse} from "./../sagas"

export const GET_PROFILE = "GET_PROFILE"
export const getProfile = (callback:(response:any)=>void, onError:(response:any)=>void) => {
  return {
    type: GET_PROFILE,
    payload: {callback, onError}
  }
}





export const SET_PROFILE = "SET_PROFILE"
export const setProfile = (user:any) => {
  return {
    type: SET_PROFILE,
    payload: user,
  }
}
  

    

export const LOGIN = "LOGIN"
export const login = ( params: {data: {email:string, password:string } } & APICallResponse) => {
    return {
      type: LOGIN,
      payload: params,
    }
}
  