import React, {createRef, FunctionComponent, useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, bootstrap, Button, Table } from "../lfit-lib"
import { Avatar } from "../lfit-lib/Components/Avatar"
import { Modal } from "../lfit-lib/Components/Modal"
import { Popover } from "../lfit-lib/Components/Popover"
import { Toast } from "../lfit-lib/Components/Toast"
import Input from "../lfit-lib/Forms/Fields/Input"
import Radio from "../lfit-lib/Forms/Fields/Radio"
import Select from "../lfit-lib/Forms/Fields/Select"
import Form from "../lfit-lib/Forms/Form"
import { toggleToast } from "../store/actions"
import { API } from "../utils/APIHandler"
import { getIMC, IMC, PRODUCT_STATUS, PROFILE_TYPES, STATUS } from "../utils/Constants"
import { Loader } from "../utils/Loader"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import { Carousel } from "../lfit-lib/Components/Carousel"
import { PlanSelectorModal } from "../components/PlanSelector"



type CustomerParams = {
  id: string;
};


export const FitnessAndHealth : FunctionComponent = (props) =>{
  const userS = useSelector((state:any)=>state.user.user)
  console.log(userS)
  let id = userS.shopify_id
  const [loading, setLoading] = useState(false)

  const [disease, setDisease] = useState(false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [suplement, setSuplements] = useState(false)
  const [disabledFH, setDisabledFH] = useState(false)
  
  const [hf, setHF] = useState<any>([])
  const [user, setUser] = useState<any>({state:"disabled"})
  const form = useRef <any> ()
  const formFH = useRef <any> ()
  const dispatch = useDispatch()
  const modal = useRef<any>(null)
  const planSelector = useRef<any>(null)
  const popovers :any = {};
  const [planList, setPlanList] = useState<any>([])
  const [plans, setPlans] = useState([])

  const [orders, setOrders] = useState([])
  const [pagination, setPagination] = useState({prev:"", next:"",prev_cur:"", next_cur:""})


  
 
  

  const getFH = () =>{

    setLoading(true)
    console.log("response")
    API.get(`/fitness-health/customer/${id}/p/${page}/s/${size}`,(response)=>{
      console.log(response)
   
      setLoading(false)
      console.log(response)
      setHF(JSON.parse(JSON.stringify(response.data.items)))
    },(response)=>{
      console.log(response)
      setLoading(false)

    })
    
  }
  
  useEffect(()=>{


    setLoading(true)
    getFH()

    API.get(`/customer/get/${id}`,(response)=>{
      //console.log(response)
      setUser(response.data)
      //console.log(form)
      form.current.fill(response.data)

      setLoading(false)
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      new bootstrap.Popover(tooltipTriggerEl, {animation:false,html: true,})
     
    })

    },(response)=>{
      //console.log(response)
      setLoading(false)
    })
  

    


    
  },[])


  const deleteFHData = (fhID:any, item:any) => {
    console.log(popovers[item.id].current)
    setLoading(true)
    API.delete(`/fitness-health/${fhID}`,(response)=>{
      //console.log(response)
      setLoading(false)
      Toast.add({message:response.message, type:"primary"})
      getFH()
      console.log(popovers[fhID])
     
    },(response)=>{
      //console.log(response)
      setLoading(false)
    })

    
  }



  






  const addFHData = (data:any, valid:boolean) => {

   

    if (valid){
      setLoading(true)

      API.upload(`/fitness-health/customer/${id}`, 
      data,
      (response)=>{
        console.log(response)
     
        setLoading(false)
        console.log(response)

        Toast.add({message: "Datos Fitness & Heath agregados", type:"primary"})
        modal.current.toggle( )
        formFH.current.reset()
        getFH()
      },(response)=>{
        //console.log(response)
        setLoading(false)

      })
    }
  }

  const hfhTab = () =>{
    let imcCalcGeneral = "0"
    let imcGeneral = "-"
    console.log(user)
    if (user.last_fitness_health){
      imcCalcGeneral = (user.last_fitness_health.weight / ((user.last_fitness_health.height/100) * (user.last_fitness_health.height/100))).toFixed(1)
      imcGeneral = getIMC(imcCalcGeneral)
    }

    return (
      <React.Fragment>
        <Modal
          className={"modal-fullscreen-sm-down"}
          title={<React.Fragment><i className={"fa fa-weight"}/> Agregar datos Fitness & Health</React.Fragment>} 
          ref={modal} 
          buttonCancel={{children:"Cancelar", color:"danger"}}
          buttonOk={{className:disabledFH?"d-none":"",children:<div><i className={"fas fa-check"}/> Agregar</div>, onClick:()=>formFH.current.submit()}}
          >

       
          <Form ref={formFH} onSubmit={addFHData}>
            <Radio onChange={(value)=>setDisease(value)} className={"mb-2"} radioType={"Button"} name={"disease"} label={"¿Padeces alguna enfermedad, alergia alimentaria o problemas digestivos como estreñimiento o gastritis? "} required options={[{value:1,label:"Sí"}, {value:0,label:"No", color:"outline-danger"}]}/>
            {disease ? <Input className={"mb-2"} as={"textarea"} required name={"disease_description"} label={"Especifica..."}/> :null}
            <Radio onChange={(value)=>setSuplements(value)} className={"mb-2"} radioType={"Button"} name={"suplement"} label={"¿Tomas algún tipo de suplementación como vitaminas, proteínas, omegas, aminoácidos, termogénicos, etc.? "} required options={[{value:1,label:"Sí"}, {value:0,label:"No", color:"outline-danger"}]}/>
            {suplement ? <Input className={"mb-2"} as={"textarea"} required name={"suplement_description"} label={"¿Qué tomas y por cuanto tiempo llevas haciéndolo? "}/>:null}
            <Radio className={"mb-2"} radioType={"Button"} name={"pregnant"} label={"¿Estas embarazada o en estado de lactancia?"} required options={[{value:1,label:"Sí"}, {value:0,label:"No", color:"outline-danger"}]}/>

            <div className={"row"}>
                <Input className={"col-12 col-lg-6"} type={"number"} required name={"weight"} label={"Peso (kg)"}/>
                <Input validations={[(value:any)=>{
                  return {valid: value > 100, message:"La estatura es en centimetros. Revisa este campo." }
                }]} className={"col-12 col-lg-6"} type={"number"} required name={"height"} label={"Estatura (cm)"}/>
                
            </div>

            <div className={"row"}>
                <Input className={"col-12 col-lg-6"} type={"number"} required name={"abdomen"} label={"Circunferencia de cintura (cm) justo en el ombligo"}/>
                <Input className={"col-12 col-lg-6"} type={"number"} required name={"cadera"} label={"Circunferencia de la cadera (cm) en la parte más ancha"}/>
            </div>
            <Input className={"mb-2"} as={"textarea"} required name={"comments"} label={"Comentarios"}/>

            <hr/>
          <div className={"mt-2 mb-2"}>
          Para poder brindarte la asesoría personalizada, 
          asignarte el menú adecuado de acuerdo a tu composición corporal y 
          comparar tus cambios al final de este reto, es necesario que nos compartas las siguientes fotos:
          </div>

              <Upload label={"Foto de frente"} name={"img_front"} required inline />
                  
   
      
              <Upload  label={"Foto de espalda"} name={"img_back"} required inline/>
                  
         
         
              <Upload  label={"Foto de perfil"}  name={"img_side_face"} required inline/>
                  
    
          
          </Form>

        </Modal>

       
       
       
       
        <Card  className={"elevated mb-3"} bodyClassName={"p-0"}>

            <div className={"bg-light p-2 px-3 fs-6 text-gray fw-normal border-bottom mb-2"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
            Historial Fitness & Health
              </div>

              <div className={"p-4 px-4 pt-0"}>
              <div className="row  align-items-center  mb-2">

              <div className={"d-flex justify-content-end"}>
                <Button onClick={()=>{  formFH.current.reset(); setDisabledFH(false);modal.current.toggle()}} color={"secondary"}><i className={"fa fa-plus"}/> Agregar</Button>
              </div>

            </div>
            </div>

          
          
            <div className="p-2">
              <hr/>
              <div className={"row text-center mb-2"}>
              <div><b className={"text-muted"}>
                Historial Fitness & Health
              </b>  </div>
              </div>
            {hf && hf.length > 0 ?<div className="row row-cols-1 row-cols-md-3 g-4 mb-3 mx-3">


              {hf.map((item:any,index:any)=>{
                console.log(item)
                  let imcCalc = (item.weight / ((item.height/100) * (item.height/100))).toFixed(1)
                  console.log(imcCalc)
                  let imc = getIMC(imcCalc)
            

                  popovers[item.id] = createRef()
                  console.log(item.id)
                  return (
                    <div key={index} className="col">
                    
                      <div  className="card h-100 border shadow-sm">
                        <span className={"bg-light border-bottom p-2"}>
                        {item.created_at.split(" ")[0]}

                          <div className="float-end" >
                          <Popover key={item.id} click ref={popovers[item.id]} title={<div>¿Estas seguro que quieres eliminar este registro {item.id} ?</div>}
                                        content={<div className={"d-flex"}><Button block onClick={()=>popovers[item.id].current.hide()} className={"flex-fill me-1"}  color={"danger"}>Cancelar</Button><Button block onClick={()=>deleteFHData(item.id, item)} className={"flex-fill"} color={"primary"}>Sí</Button></div>}>   
                              <button type="button"  className="btn-close" aria-label="Close"></button>

                          </Popover>
                          </div>


                          </span>

                        <div className="card-body btn p-2">
        
                      
                            <div className={"card-title d-flex align-items-center justify-content-center text-start mb-0"}>
                                <i className={"fs-1 fas fa-weight d-md-none d-lg-block"}/>
                                
                                
                                
                                <div className={"ms-2"}>
                                  <h5 className="mb-1"><b>IMC {imcCalc}</b></h5>

                                  <Popover title={<div>{IMC[imc].text}</div>}
                                        content={<div>{IMC[imc].description}</div>}>
                                  <div className={"d-flex"}>

                                    <span  style={{fontSize:10}} className={`badge bg-${IMC[imc].color}`}>
                                      <div>
                                        <i className={IMC[imc].iconClass}/> {IMC[imc].text}
                                      </div>
                                      </span> 

                                      </div>
</Popover>
                                </div>
                            </div>
                     
            
                            <hr className={"my-2"}/>


                            <div className={"text-center"}>


                            {item && item.age  && item.age != -1 ? <h6 className="mb-1">
                              <div className={"d-flex align-items-center justify-content-center "}>
                                <div style={{flex:1}}>Edad{" "}</div>
                                <div style={{flex:1}}><b>{item.age  && item.age != -1 ? item.age + " años" : "No es posible calcular la edad" }</b></div>
                              </div>
                            </h6>:null}
                            <hr className={"my-2"}/>

                              <div className={"d-flex align-items-center justify-content-center"}>
                                <div className={"text-truncate"} style={{flex:1}}>Peso<br/><b>{item.weight} kg</b></div>
                                <div className={"text-truncate"} style={{flex:1}}>Estatura<br/><b>{item.height} cm</b></div>
                              </div>

                              <div className={"d-flex align-items-center justify-content-center"}>
                                <div className={"text-truncate"} style={{flex:1}}>Cintura<br/><b>{item.abdomen} cm</b></div>
                                <div className={"text-truncate"} style={{flex:1}}>Cadera<br/><b>{item.cadera} cm</b></div>
                              </div>

                              <div className={"d-flex align-items-center justify-content-center"}>
                                <div className={"text-truncate"} style={{flex:1}}>Enfermedades<br/><b>{item.disease ? "Sí":"No"}</b></div>
                                <div className={"text-truncate"} style={{flex:1}}>Suplementos<br/><b>{item.suplement? "Sí":"No"}</b></div>
                              </div>
                            
                            </div>
                            <hr className={"my-2"}/>
                            <Button color={"light"} block onClick={()=>{
                        setLoading(true)
                        setDisabledFH(true)
                        setDisease(item.disease )
                        setSuplements(item.suplement )
                        
                        setTimeout(() => {    
                          modal.current.toggle()
                          setLoading(false)
                          formFH.current.fill(item)
                        }, 100);
                   
                   
                      }}>Ver detalles</Button>
                        </div>
                      
                      </div>
              
                    </div>
                  )
              })}
              </div>:<div className={"d-flex justify-content-center align-items-center mb-5 text-muted fs-5"}>No hay datos</div>}
            </div>
          </Card>
      </React.Fragment>
    )
  }






  return (
      <React.Fragment>
        {loading? <Loader message={"Cargando..."}/>:null}


        <div className={"container"}>
       
            {hfhTab()}

    
    
        </div>
        
    </React.Fragment>)
  

}
