
import classNames from "classnames"
import React, {FunctionComponent, useState} from "react"
import { getValue } from "../Utils/Tools"
import { Button } from "./Button"

type Column = {
    label: string,
    key: string,
    formatter?: (value:any, row:any, parent?:any)=> FunctionComponent | Function | React.Component | JSX.Element,
    headerClassName?: string,
    headerStyle?: React.CSSProperties,
    style?: React.CSSProperties,
    className?: string,


  

}

type TableProps = {
    data?: any[],
    parent?:any,
    columns: Column[],
    withRowMenu?:boolean,
    headerClassName?: string,
    headerStyle?: React.CSSProperties,
    columnsStyle?: React.CSSProperties,
    selectable?:boolean,
    footer?: ()=> FunctionComponent | Function | React.Component | JSX.Element,
    onRowClick?: (row:any, index:number)=>void,
    nestedTable?: any,
    footerClassName?:string,
    footerStyle?: React.CSSProperties,
}

const defaultProps : TableProps = {
    data:[],
    columns:[],
    headerClassName:"",
    withRowMenu:false,
    onRowClick:()=>{}
}


export const Table : FunctionComponent<TableProps> = (props) =>{

    const {withRowMenu,columns, headerClassName, headerStyle, data, columnsStyle,footerStyle, onRowClick, selectable, footer, footerClassName, parent } = props

    const [selectedRow, setSelectedRow] = useState(-1)


  return (<div className="table-responsive border border-light rounded">
            <table className={`table align-middle  ${selectable ? "table-hover":"" } table-nowrap  m-0`}>
                <thead style={{borderTop:"0"}}>
                      <tr style={{borderTop:"0"}}>
                        {withRowMenu?<th scope="col" style={{width:20}}>#</th>:null}

                        {columns?.map((column : Column, index : any) => (
                            <th key={index} scope="col" style={{...headerStyle, ...column.headerStyle}}  className={`${classNames(headerClassName, column.headerClassName )}`}>
                                {column.label}
                            </th>))}
                      </tr>
                 
                </thead>

                <tbody>
                    {data?.map((row, rid) => (
                        <React.Fragment key={rid}>
                        <tr key={rid} className={`${selectedRow == rid ? "active":""}`}>
                            {withRowMenu?<th scope="row">
                                
                            {rid+1}
                            </th>:null}

                            {columns.map((col : Column, cid) => {
                 
                        return (
                        <td  onClick={()=>{
                                onRowClick && onRowClick(row, rid) 
                                setSelectedRow(rid)
                            }} key={cid} style={{...columnsStyle, ...col.style}}  className={`${classNames(col.className )}`}>
                            {col.formatter ? col.formatter(getValue(row,col.key), row, parent) : getValue(row,col.key)}
                        </td>)})}
                    </tr>

                    {
                        props.nestedTable ? 
                        (
                            
                        <tr>
                            <td className="p-0 m-0 " colSpan={columns.length}>
                            <div className="collapse " id={"collapse"+row.id}>

                                <div className="d-flex">
                                    <div data-bs-toggle="collapse" data-bs-target={"#collapse"+row.id} role="button" aria-expanded="false" aria-controls="collapseExample" className="bg-body border border-light align-self-stretch d-flex justify-content-center align-items-center btn rounded-0"style={{width:"5rem"}}>
                                        <i className="fas fa-chevron-up  " />
                                    </div>
                                    <div  style={{flex:1}}>
                                    <Table {...props.nestedTable.props} footer={props.nestedTable.footer} data={row[props.nestedTable.key]} parent={row} columns={props.nestedTable.columns} />
                                </div>
                                </div>
                                </div>
                            </td>
                        </tr>)
                        :null
                    }
                    
                    </React.Fragment>
                    ))}
                </tbody>

 <tfoot>
                
                    <tr>
                    <td className={`${classNames(footerClassName)}`} style={footerStyle} colSpan={columns.length +  (withRowMenu?1:0)}>

                            {footer && footer()}
                     
                    </td>

             
                    </tr>
             
                </tfoot>
                
            </table>
    </div>)
  

}

Table.defaultProps = defaultProps;

