

import classNames from "classnames"
import React, {ForwardRefRenderFunction, forwardRef, useRef, useState, useImperativeHandle} from "react"
import { BaseField, defaultFieldProps, FieldProps } from "./BaseField"
import JoditEditor from "jodit-react";

export type RichTextEditorProps = {



}
const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    tabIndex:1,
    useSplitMode:true
}

class RichTextEditor extends BaseField<RichTextEditorProps & React.HTMLProps<HTMLButtonElement>> {

    

    onChange = (e:any) =>{
        let value  = e


        if (value && value != ""){
            this.setValue(e)
        }else{
            this.setValue(null)
        }

    }
   

    render(){
        const { placeholder, type, name, className , disabled, label, as} = this.props
        const { error } = this.state


        let style : any = disabled ? {backgroundColor:"#f6f6f6"}:{}
        if (this.props.style){
            style = {...this.props.style,...style }
        }

        return (
            <div className={`${className}`}>
                {label ? <label htmlFor={name} className="form-label">{label}</label>:null}
                <div className={label?"":"form-floating"}>
                <JoditEditor
                key={this.id}
                value={this.state.value || ""}
                config={config}
		onBlur={this.onChange} // preferred to use only this option to update the content for performance reasons

            />
                   
                    <label htmlFor={name} className="form-label">{placeholder}</label>
                </div>
                <div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
            </div>
        )
    }
}


export default RichTextEditor