
import classNames from "classnames"
import React, {ForwardRefRenderFunction, forwardRef, PropsWithChildren, useState, useImperativeHandle} from "react"
import { BaseField, defaultFieldProps, FieldProps } from "./BaseField"

export type InputProps = {

}

class Input extends BaseField<InputProps & React.HTMLProps<HTMLButtonElement>> {


    onChange = (e:any) =>{
        let value  = e.target.value


        if (value && value != ""){
            this.setValue(e.target.value)
        }else{
            this.setValue(null)
        }

    }
   

    render(){
        const { placeholder, type, name, className , disabled, label, as} = this.props
        const { error } = this.state


        let style : any = disabled ? {backgroundColor:"#f6f6f6"}:{}
        if (this.props.style){
            style = {...this.props.style,...style }
        }

        return (
            <div className={`${className}`}>
                {label ? <label htmlFor={name} className={`form-label ${type == "color"? "mb-0":""}`}>{label}</label>:null}
                <div className={label?"":"form-floating"}>

                   
                    <input  {...this.props as any} value={this.state.value || ""} onChange={this.onChange} style={style} className={`form-control ${classNames(error?"is-invalid":"")}`} type={type} placeholder={placeholder} id={name}></input>
                   {placeholder ? <label htmlFor={name} className="form-label">{placeholder}</label> :null}
                </div>
                <div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
            </div>
        )
    }
}


export default Input