
import classNames from "classnames"
import React, { FunctionComponent, useEffect, useState } from "react"
import { ButtonColor, Color } from "../Utils/Colors"
import { Popover, PopoverBody, PopoverHeader } from "reactstrap"
import { uniqueId } from "../../utils/Constants"


export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

  color?: ButtonColor | string,
  size?: "sm" | "lg" | "md",
  className?: string,
  disabled?: boolean,
  loading?: boolean,
  popoverBgColor?: Color,
  popoverTextColor?: Color,
  block?: boolean,
  confirmationPopover?: boolean,
  okButtonColor?: ButtonColor,
  confirmationPopoverHeader?: string,
  confirmationPopoverOkText?: string,
  onClickConfirmationPopoverOk?: () => void,

}

const defaultProps: ButtonProps = {
  disabled: false,
  size: "md",
  popoverBgColor: "danger",
  popoverTextColor: "white",
  okButtonColor: "danger",
  confirmationPopoverHeader: "¿Estas seguro que quieres realizar esta operación?",
  confirmationPopoverOkText: "Sí",
  onClickConfirmationPopoverOk: () => { }
}


export const Button: FunctionComponent<ButtonProps> = (props) => {

  const { size, color, block, className, popoverTextColor, onClickConfirmationPopoverOk, onClick, popoverBgColor, disabled, children, loading, confirmationPopover, confirmationPopoverHeader, confirmationPopoverOkText, okButtonColor } = props
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [id] = useState(uniqueId())
  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  }


  let colorX = color?.startsWith("#") ? "light" : color



  return (
    <>
      <button id={id} type={"button"} {...props} disabled={disabled} className={`btn btn-${classNames(colorX)} btn-${classNames(size)} ${className} ${block ? "w-100" : ""}`} onClick={(event) => {
        if (confirmationPopover) {
          toggle()
        }
        if (onClick) {
          onClick(event)
        }
      }}>
        {loading ? <div className={"d-flex"}>
          <span className={` align-self-center spinner-grow spinner-grow-sm`} role="status" aria-hidden="true"></span>
          <span className={"align-self-center ms-1"}>Cargando...</span>
        </div> : children}

      </button>

      {confirmationPopover ? <Popover popperClassName={"shadow-lg"} placement="top" isOpen={popoverOpen} target={id} toggle={toggle}>
        <PopoverHeader className={`bg-${popoverBgColor} text-${popoverTextColor} fw-normal`}>{confirmationPopoverHeader}</PopoverHeader>
        <PopoverBody >
          <div className={"d-flex justify-content-center"}>
            <Button block className={"flex-fill me-1"} color={"light"} onClick={toggle}>Cancelar</Button>

            <Button block className={"flex-fill"} onClick={() => {
              toggle()
              onClickConfirmationPopoverOk && onClickConfirmationPopoverOk()
            }} color={okButtonColor}>{confirmationPopoverOkText}</Button>

          </div>
        </PopoverBody>
      </Popover> : null}


    </>)


}

Button.defaultProps = defaultProps;

