import { SET_ALL_USERS, SET_PROFILE } from "./actions"

const initialState = {
  user:{}
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      state = { ...state, user: action.payload}
      break
    case SET_PROFILE:
      state = { ...state, user: action.payload}
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user
