
import classNames from "classnames"
import React, {ForwardRefRenderFunction, forwardRef, PropsWithChildren, useState, useImperativeHandle} from "react"
import { BaseField, defaultFieldProps, FieldProps } from "./BaseField"

export type SelectProps = {
    classSize? :  "sm" | "lg" | "md",
    options: {label?:string | React.ReactNode, value: string | number}[],
  
}



class Select extends BaseField<React.HTMLProps<HTMLSelectElement> & SelectProps> {


    onChange = (e:any) =>{
        let value  = e.target.value


        if (value && value != ""){
            this.setValue(e.target.value)
            this.props.onChange && this.props.onChange(e.target.value)
        }else{
            this.setValue(null)
            this.props.onChange && this.props.onChange(null)
        }

        

    }
   

    render(){
        const { placeholder, options, name, className, label, classSize } = this.props
        const { error } = this.state

        let selectSize = classSize || "lg"

        return (
            <div className={`${className}`}>
               
                {label ? <label htmlFor={name} className="form-label">{label}</label>:null}
                <select {...this.props} value={this.state.value == null ? "" : this.state.value} onChange={this.onChange}  className={`form-select bg-white form-select-${selectSize}`} aria-label={`.form-select-${selectSize} example`} id={this.id}>
                    { this.props.defaultValue ? null: <option value={""} disabled>{placeholder}</option>}
                    {options.map((option, index)=><option key={index} value={option.value}>{option.label || option.value}</option>)}
              
                </select>
                <div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
            </div>
        )
    }
}


export default Select