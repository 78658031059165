
import React, {useState, FunctionComponent, useEffect} from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { API } from "../../utils/APIHandler";
import { shadeColor } from "../Utils/Colors";
import { Avatar } from "./Avatar";
import { ProfileMenu } from "./ProfileMenu";


export type HeaderProps = {
    logo?: string,
    color?: string,
    bgColor?: string,
    title?: string,
    sidebarTarget?:string,
    bgOverlapHeight?: number,
    bgOverlapColor?:string
}
const defaultProps : HeaderProps = {
  bgColor:"transparent",
  color:"rgb(160, 160, 160)",
  sidebarTarget:"",
  bgOverlapHeight:100,
  bgOverlapColor:"transparent"
}


export const Header : FunctionComponent<HeaderProps> = (props) =>{

  const {logo, bgColor, color, sidebarTarget} = props

  const [scrolled, setScrolled] = useState(false)
  const [value, setValue] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any[]>([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const bColor = useSelector((state:any)=>state.ui.bannerColor)
  const user = useSelector((state:any)=>state.user.user)
  useEffect(()=>{

    console.log(user)

    window.addEventListener("scroll",(e:any)=>{
      var element = document.querySelector('#header-bar');
      var content = document.querySelector('.header-bar-wrapper');
      //var position = element?.getBoundingClientRect() || {top:0,bottom:0};

      if (element && element.clientHeight){
        //console.log(window.scrollY > element.clientHeight);
        setScrolled(window.scrollY > element.clientHeight );

        if (window.scrollY > element.clientHeight ){
          element.classList.add("menu-scroll-v1")
          content?.classList.add("content-scrolled")
        }
        
        if (window.scrollY < 10){


          element.classList.remove("menu-scroll-v1")
          content?.classList.remove("content-scrolled")
        }


      }

  
    })
   

  },[])



  return (
      <header id={"header-bar"} className={`header-v1 w-100  bg-${bgColor}`}>

      <Navbar style={{backgroundColor: bgColor}} className={`d-flex navbar navbar-expand-md bd-navbar flex-row px-0 `}  light expand="md">

      <div className="d-flex align-items-center justify-content-center flex-grow-1  px-2 px-sm-3">

        <NavbarBrand  className="navbar-nav flex-row align-items-center flex-grow-1" >


          <div className="d-flex navbar-nav flex-row d-md-none align-items-center">
            <button className="btn d-block fs-4 text-white" type="button" data-bs-toggle="offcanvas" data-bs-target={"#"+sidebarTarget} aria-controls={sidebarTarget}>
              <i className={"fas fa-bars"} />
            </button>
            {logo ? <img className="d-none d-sm-block" height={35}  src={logo}/>:null}
          </div>

          
           
        </NavbarBrand>


    
        <div className="navbar-nav d-flex flex-row">
      
          
            <ProfileMenu bgColor={`soft-${color}`} color={color} user={user || {}}/>
     
        </div>


      </div>

      </Navbar>

    </header>
          )
  

}

Header.defaultProps = defaultProps;