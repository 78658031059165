
import React, {FunctionComponent} from "react"
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { TextColor } from "../Utils/Colors";



type SidebarProps = {
    logo?: string,
    sidebarId?: string,
    navMenuLink?: {to:string, icon:string, label:string, color?:TextColor, subMenu?: {to:string, icon:string, label:string, color?:TextColor}[]}[],
    navMenuLinkBottom?: {to:string, icon:string, label:string, color?:TextColor}[],
    
}

const defaultProps : SidebarProps = {
    logo:"",
    navMenuLink:[],
    navMenuLinkBottom:[]
}


export const  Sidebar : FunctionComponent<SidebarProps> = (props) =>{


    const { logo, sidebarId,navMenuLink, navMenuLinkBottom} = props;
    const location = useLocation()

    const menu = () => {

        return (
            <React.Fragment>

<ul className="nav nav-pills flex-column mb-auto">

      {navMenuLink?.map((item, index)=><li key={index} className="nav-item">
        
        
        
        {item.subMenu ? 

        <>
        <Link data-bs-dismiss="offcanvas" to={item.to} className={`nav-link  text-center ${location.pathname.includes(item.to) ? "active":""} justify-content-center align-items-center`} aria-current="page">
           
            <i className={`flex-shrink-1 ${item.icon} me-2 text-${item.color} text-center`} style={{width:20}}/> <br/>
            <div>{item.label}</div>
        </Link>
            
        <ul className="nav nav-pills flex-column mb-auto ms-4">
           {item.subMenu?.map((subItem, subIndex)=> <li key={index + "-" +subIndex} className="nav-item">
            <Link data-bs-dismiss="offcanvas" to={subItem.to} className={`nav-link   ${location.pathname.includes(subItem.to) ? "active":""} d-flex align-items-center border-0 bg-transparent`} aria-current="page">
                <i className={`flex-shrink-1 ${subItem.icon} me-2 text-${subItem.color} text-center`} style={{width:20}}/>
                {subItem.label}
                </Link>
            </li>)}


        </ul>
        </>
        :
        <Link data-bs-dismiss="offcanvas" to={item.to} className={`nav-link mt-3 h5 text-center ${location.pathname.includes(item.to) ? "active":""} justify-content-center align-items-center`} aria-current="page">
          <i className={`flex-shrink-1 ${item.icon} fs-2 text-${item.color} text-center`} style={{width:20}}/><br/>
          <div>{item.label}</div>
        </Link>}


      </li>)}
      <li  className="nav-item">
        <a data-bs-dismiss="offcanvas" target="_blank"  href={"https://www.librementefit.mx"} className={`nav-link mt-3 h5 text-center justify-content-center align-items-center`} aria-current="page">
          <i className={`flex-shrink-1 fas fa-store fs-2 text- text-center`} /><br/>
          <div>Tienda</div>
        </a>
      </li>

      <li  className="nav-item">
        <a data-bs-dismiss="offcanvas" target="_blank"  href={"https://www.librementefit.mx/pages/faqs"} className={`nav-link mt-3 h5 text-center justify-content-center align-items-center`} aria-current="page">
          <i className={`flex-shrink-1 fas fa-question-circle fs-2 text- text-center`} /><br/>
          <div>Preguntas frecuentes</div>
        </a>
      </li>
     
</ul>

<ul className="nav nav-pills flex-column">

      {navMenuLinkBottom?.map((item, index)=><li key={index} className="nav-item">
        <Link data-bs-dismiss="offcanvas"  to={item.to} className={`nav-link   ${location.pathname.includes(item.to) ? "active":""} d-flex align-items-center`} aria-current="page">
          <i className={`flex-shrink-1 ${item.icon} me-2 text-${item.color} text-center`} style={{width:20}}/>
          {item.label}
        </Link>
      </li>)}
     
</ul>


<hr/>
            <div className={"d-flex align-items-center justify-content-center pb-3"}>
                <a className={"text-decoration-none "} target={"_blank"} href={"https://www.librementefit.mx/"}>Ir a la tienda<i className={"fas fa-external-link-alt ms-2"}/> </a>
            </div>
            </React.Fragment>
        )
    }



  return (
    <div style={{zIndex:1}}>
   
        <div className="offcanvas offcanvas-start p-0 pt-0 pe-2" tabIndex={-1} id={sidebarId} aria-labelledby={`${sidebarId}Label`}>

            <div className="offcanvas-header">
                <div className="offcanvas-title px-5" id="offcanvasExampleLabel">
                    <img  className={"img-fluid"} src={logo}/>
                </div>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
           
            </div>
            <hr className={"mt-0"}/>
                
            {menu()}
   
        </div>

        <div className={"main-sidebar position-fixed border-end"}>
            <div className={"sidebar d-flex bg-white flex-column flex-shrink-0  vh-100 p-0 pt-0"}>
           
                <div className="nav-item">

                    <div className="container-fluid mt-3 justify-content-center ">

                        <div  className="navbar-nav flex-row align-items-center" >
                            {logo ? <img  className={"img-fluid"} src={logo}/>: null}
                            
                        </div>

                        <hr/>

                
                    </div>

     
       

                </div>

                {menu()}
                 
      
            
            
        
            </div>
        </div>
    </div>
    )

}

Sidebar.defaultProps = defaultProps;


