import "./assets/scss/app.scss"

import React, { Suspense, useEffect } from "react"

import { authRoutes, publicRoutes } from "./routes/allRoutes"
import Authmiddleware from "./routes/middleware/Authmiddleware"






import { Layout } from "./components/Layout";
import { BrowserRouter } from "react-router-dom";


let c = 1
const App = () =>{

  useEffect(() => {
    var el = document.getElementById('html-loader'); 
    el.remove();
  }, [])


  const Loader = ({message}) => {
    return (
      <div id="preloader" style={{display:"block"}}>
      <div id="status" style={{display:"block"}}>
        <div className="spinner-chase">
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
        </div>
      </div>
      <div className={"text-center"} style={{top:"50%", position:"absolute", marginTop:50, width:"100%"}}>{message}</div>

    </div>
    )
  }
    return (



      <React.Fragment>
      
            <Suspense fallback={<Loader message={"Cargando..."}/>}>
            <BrowserRouter>
              
              <Authmiddleware
                routes={publicRoutes}
                authRequired={false}
                layout={React.Fragment}
                exact
              />
      


              <Authmiddleware
                routes={authRoutes}
                authRequired
                loader={()=><Loader message={"Validando crendenciales..."} />}
                layout={Layout}
                exact
              />
        </BrowserRouter>
       

          
      </Suspense>
    </React.Fragment>

    );

}

export default App
