export const URL_STORE = "https://www.librementefit.mx/"

export const PROFILE_TYPES :any = {
    "ADMIN":"Administrador",
    "CUSTOMER": "Cliente"
}


let lastId = 0;

export function uniqueId(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}

export const STATUS :any  = {
    "enabled": {color:"primary", text:"ACTIVO", iconClass:"fas fa-check-circle", description:"La cuenta del usuario esta activa y completamente funcional."},
    "disabled": {color:"red", text:"INACTIVO", iconClass:"fas fa-exclamation-triangle", description:"La cuenta del usuario esta inactiva. El usuario NO puede hacer compras con este correo, NO puede acceder al ecommerce ni a la Zona Fit. Esta cuenta solo puede ser habilitada desde el panel de Shopify."},
    "invited": {color:"secondary", text:"INVITACIÓN ENVIADA", iconClass:"fas fa-paper-plane", description:"La invitación fue enviada. El usuario puede aceptar la invitación en su correo  y configurar su cuenta o tu puedes enviar una invitación nueva desde el perfil del usuario."},
    "declined": {color:"danger", text:"INVITACIÓN RECHAZADA", iconClass:"fas fa-times-circle", description:"La última invitación enviada fue rechazada. Si el usuario intenta acceder puede activar su cuenta o tu puedes enviar una invitación nueva desde el perfil del usuario."},
    "suspended": {color:"warning", text:"SUSPENDIDA", iconClass:"fas fa-exclamation-circle", description:"La cuenta del usuario esta suspendida. El usuario SI puede hacer compras con este correo y SI puede acceder al ecommerce pero no puede acceder a la Zona Fit."}

}

export const PRODUCT_STATUS :any  = {
    "active": {color:"primary text-white", text:"ACTIVO", iconClass:"fas fa-check-circle", description:"La cuenta del usuario esta activa y completamente funcional."},
    "archived": {color:"danger text-white", text:"ARCHIVADO", iconClass:"fas fa-trash", description:"La cuenta del usuario esta inactiva. El usuario NO puede hacer compras con este correo, NO puede acceder al ecommerce ni a la Zona Fit. Esta cuenta solo puede ser habilitada desde el panel de Shopify."},
    "draft": {color:"light text-dark", text:"BORRADOR", iconClass:"fas fa-pencil-ruler", description:"La última invitación enviada fue rechazada. Si el usuario intenta acceder puede activar su cuenta o tu puedes enviar una invitación nueva desde el perfil del usuario."},

}
export const IMC :any  = {
    "peso_bajo": {color:"warning", text:"Peso bajo", iconClass:"fas fa-exclamation-circle", description:"La cuenta del usuario esta activa y completamente funcional."},
    "peso_normal": {color:"primary", text:"Peso normal", iconClass:"fas fa-check-circle", description:"La cuenta del usuario esta inactiva. El usuario NO puede hacer compras con este correo, NO puede acceder al ecommerce ni a la Zona Fit. Esta cuenta solo puede ser habilitada desde el panel de Shopify."},
    "sobrepeso": {color:"orange", text:"Sobrepeso", iconClass:"fas fa-exclamation-circle", description:"La cuenta del usuario esta inactiva. El usuario NO puede hacer compras con este correo, NO puede acceder al ecommerce ni a la Zona Fit. Esta cuenta solo puede ser habilitada desde el panel de Shopify."},
    "obesidad1": {color:"danger", text:"Obesidad Grado I", iconClass:"fas fa-exclamation-circle", description:"La invitación fue enviada. El usuario puede aceptar la invitación en su correo  y configurar su cuenta o tu puedes enviar una invitación nueva desde el perfil del usuario."},
    "obesidad2": {color:"danger", text:"Obesidad Grado II", iconClass:"fas fa-exclamation-circle", description:"La última invitación enviada fue rechazada. Si el usuario intenta acceder puede activar su cuenta o tu puedes enviar una invitación nueva desde el perfil del usuario."},
    "obesidad3": {color:"danger", text:"Obesidad Grado III", iconClass:"fas fa-exclamation-circle", description:"La cuenta del usuario esta suspendida. El usuario SI puede hacer compras con este correo y SI puede acceder al ecommerce pero no puede acceder a la Zona Fit."}
}

export const getIMC = (imc:any) => {
    imc = parseFloat(imc)
    if(imc < 18.5){
        return "peso_bajo"
    }
    if(imc <= 24.9){
        return "peso_normal"
    }
    if(imc <= 29.9){
        return "sobrepeso"
    }
    if(imc <= 34.9){
        return "obesidad1"
    }
    if(imc <= 39.9){
        return "obesidad2"
    }
    return "obesidad3"
}