import { TOGGLE_SIDEBAR, TOGGLE_TOAST, SET_BANNER_COLOR } from "./actions"

const initialState = {
  sidebar:false,
  toast:false,
  toastMessage: "",
  bannerColor: "#58bb43"
}

const ui = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      state = { ...state, sidebar: action.payload}
      break
    case TOGGLE_TOAST:
      state = { ...state, toast: action.payload.toggle, toastMessage: action.payload.toastMessage }
      break
    case SET_BANNER_COLOR:
      state = { ...state, bannerColor: action.payload}
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ui
