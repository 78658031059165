
import React, {FunctionComponent} from "react"
import { Link } from "react-router-dom"
import { Avatar } from "./Avatar"


type ProfileMenuProps = {
  user?: any,
    bgColor?: string,
    color? : string,
    img? : string,
}

const defaultProps : ProfileMenuProps = {
   
    bgColor:"primary",
    color:"black"
}


export const  ProfileMenu : FunctionComponent< ProfileMenuProps> = (props) =>{

    const {user, color, img, bgColor} = props


  return (
    <div className={"ms-auto dropdown nav-item"}>
        <a aria-haspopup="true" aria-expanded="false" id="userInfo" data-bs-toggle="dropdown" className="pe-0 dropdown-toggle nav-link " role="button">

          <div className={"d-flex align-items-center fs-6 fw-bold"}>
                
                <Avatar size={"md"} img={img} initials={(user.full_name || "-" ).substr(0,1)} color={bgColor}/>
                <div style={{color:color}} className={`ms-2 me-2 fs-5 te d-none d-md-flex text-${color} fw-normal align-items-center`}>
                  {user.full_name} <i className={"ms-2 fas fa-chevron-down"}/>
                </div>
            
          </div>

        </a>
        <div aria-labelledby="userInfo"  className="dropdown-menu-animated dropdown-menu dropdown-menu-end position-absolute shadow-lg w-100">
      {/* <a href="#" className="dropdown-item" role="button">Settings</a> */}
      <Link to={`/mi-cuenta`} className="dropdown-item" role="button"><i className="fas fa-user me-2" />Mi cuenta</Link>
      <hr className="dropdown-divider" role="separator"/>
      <span  onClick={()=>{
        localStorage.clear(

        )
        window.location.href = "/login"
      }} className="dropdown-item text-danger"><i className="fas fa-sign-out-alt me-2" />Salir</span></div>

    </div>
  )
  

}

ProfileMenu.defaultProps = defaultProps;

