import React, { createRef, FunctionComponent, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Card, bootstrap, Button } from "../lfit-lib"
import { Avatar } from "../lfit-lib/Components/Avatar"
import { Modal } from "../lfit-lib/Components/Modal"
import { Popover } from "../lfit-lib/Components/Popover"
import { Toast } from "../lfit-lib/Components/Toast"
import Input from "../lfit-lib/Forms/Fields/Input"
import Radio from "../lfit-lib/Forms/Fields/Radio"
import RichTextEditor from "../lfit-lib/Forms/Fields/RichTextEditor"
import Select from "../lfit-lib/Forms/Fields/Select"
import TextArea from "../lfit-lib/Forms/Fields/TextArea"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import Form from "../lfit-lib/Forms/Form"
import { copyStringToClipboard } from "../lfit-lib/Utils/Tools"
import { resetBanner, setBannerColor, toggleToast } from "../store/actions"
import { API } from "../utils/APIHandler"
import { getIMC, IMC, PRODUCT_STATUS, PROFILE_TYPES, STATUS, URL_STORE } from "../utils/Constants"
import { Loader } from "../utils/Loader"



type PlanParams = {
  id: string;
};

let uploadType = ""
let plan_version_id = ""

export const Plan: FunctionComponent = (props) => {

  const navigation = useHistory()
  const { id } = useParams<PlanParams>();
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(null)


  const form = useRef<any>();
  const modalImage = useRef<any>();
  const formImage = useRef<any>();
  const formProduct = useRef<any>();
  const modalUpload = useRef<any>();
  const formUpload = useRef<any>();

  const modalVersion = useRef<any>();
  const formVersion = useRef<any>();


  const [planTypes, setPlanTypes] = useState([])
  const [product, setProduct] = useState<any>({ status: "draft" })

  const [plan, setPlan] = useState<any>({ status: "draft", resources: [], versions: [] })

  const dispatch = useDispatch()

  let url = URL_STORE + "products/" + product.handle



  const getData = () => {
    setLoading(true)
    API.get(
      `/plan/types`,
      (response) => {
        setPlanTypes(response.data)
        API.get(`/plan/${id}`, (response) => {

          if (response.data && response.data.id) {
            console.log(response)
            setProduct(JSON.parse(JSON.stringify(response.data)))

            if (response.data && response.data.variants && response.data.variants.length > 0){
              let variant = response.data.variants[0]
             response.data.inventory = variant.inventory_management &&  variant.inventory_policy == "deny" ? "deny" : "continue"
             response.data.requires_shipping = variant.requires_shipping
       
            }
            formProduct.current.fill(response.data)
            response.data = response.data.plan
            if (response.data.resources) {
              response.data.resources =
                response.data.resources.map((r: any, index: any) => {

                  r.display_order = index
                  return r
                })
            }
            setPlan(response.data)
            dispatch(setBannerColor(response.data.color))
            form.current.fill(response.data)
          } else {
            navigation.goBack()
          }




          setLoading(false)

        }, (response) => {
          //console.log(response)
          setLoading(false)
        })

      },
      (error) => {

      }
    )

  }


  useEffect(() => {



    getData()

    return () => {
      dispatch(resetBanner())
    }
  }, [id])
  const updateGeneralInfo = (data: any, valid: boolean) => {
    if (valid) {
      setLoading(true)
      API.put(`/plan/${id}/product`,
        data, () => { 
          
      
          getData(); setLoading(false) }, () => { setLoading(false) })
    }

  }


  const updatePlanInfo = (data: any, valid: boolean) => {
    if (valid) {
      setLoading(true)
      API.put(`/plan/${id}`,
        data, () => { getData(); setLoading(false) }, () => { setLoading(false) })
    }

  }

  const deletePlan = () => {
    setLoading(true)
    API.delete(`/plan/${id}`, () => {
      setLoading(false)
      navigation.replace("/plans")
    }, () => { setLoading(false) })


  }

  const uploadPlanImage = (data: any, valid: boolean) => {
    console.log(data, valid)
    if (valid) {
      setLoading(true)
      API.upload(`/plan/upload-image/${id}`,
        data,
        () => {
          getData()
          modalImage.current.toggle()
        },
        () => {
          setLoading(false)
          Toast.add({ message: "Error", type: "danger" })
        })
    }

  }

  const uploadFile = (data: any, valid: boolean) => {
    console.log(data, valid)
    if (valid) {
      setLoading(true)
      API.upload(`/plan/upload/${id}/${uploadType}`,
        { ...data, plan_version_id: plan_version_id },
        () => {
          getData()
          modalUpload.current.toggle()
        },
        () => {
          setLoading(false)
          Toast.add({ message: "Error", type: "danger" })
        })
    }

  }

  const addVersion = (data: any, valid: boolean) => {
    console.log(data, valid)
    if (valid) {
      setLoading(true)
      API.post(`/plan/version/${id}`,
        data,
        () => {
          getData()
          modalVersion.current.toggle()
        },
        () => {
          setLoading(false)
          Toast.add({ message: "Error", type: "danger" })
        })
    }

  }


  const profileCard = () => {
    return (
      <div>


        <Popover title={<div>{PRODUCT_STATUS[product.status].text}</div>}
          content={<div> {PRODUCT_STATUS[product.status].description}</div>}>
          <div className={`bg-${PRODUCT_STATUS[product.status].color} text-center p-2 px-3 fs-6 fw-bold`} style={{ borderBottomRightRadius: "0.05rem", borderBottomLeftRadius: "0.05rem" }}>

            <div><span><i className={PRODUCT_STATUS[product.status].iconClass} />{" "}{PRODUCT_STATUS[product.status].text}</span>
            </div>


          </div>
        </Popover>


        <div className={"p-4"}>
          <div className={"d-flex flex-column align-items-center"}>




            <div className={""}>
              {product.images && product.images.length > 0 ? <img src={product.images[0].src} className="img-fluid rounded border" /> :

                <div className={"w-100 border d-flex justify-content-center align-items-center bg-light rounded p-4"} style={{ height: 150 }}>
                  NO HAY IMAGEN
                </div>
              }

              <Button onClick={() => modalImage.current.toggle()} block size={"sm"} className={"mt-3 "} color={"outline-secondary"}>Actualizar imagen</Button>


            </div>

            <div className={"mt-2 text-center"}>

              <div className="fs-3 text-capitalize">{product.title}</div>
              <div className="fs-5 text-capitalize mt-1">{plan.plan_type}</div>
            </div>


          </div>




          <hr />

          <div className={"row text-center"}>

            <div className={"col-6"}>
              <div className={"fw-bold"}>Fecha de creación</div>
              <div>{plan.created_at}</div>
            </div>
            <div className={"col-6"}>
              <div className={"fw-bold"}>Última actualización</div>
              <div>{plan.updated_at}</div>

            </div>



          </div>
        </div>


      </div>
    )
  }




  const tabsSelect = (tabClass: string, buttonClass: string = "") => {

    return (
      <React.Fragment>
        <li className={tabClass}>
          <button className={`nav-link  rounded-0  w-100 h-100 ${buttonClass}`}
            id="product-tab" data-bs-toggle="tab" data-bs-target="#product" type="button" role="tab" aria-controls="product" aria-selected="true"
          >Información general</button>
        </li>

        <li className={tabClass}>
          <button className={`nav-link  rounded-0  w-100 h-100 ${buttonClass}`}
            id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"
          >Información del plan</button>
        </li>
        <li className={tabClass}>
          <button className={`nav-link rounded-0  w-100 h-100 ${buttonClass}`}
            id="buys-tab" data-bs-toggle="tab" data-bs-target="#buys" type="button" role="tab" aria-controls="buys" aria-selected="true">
            Documentos generales
          </button>
        </li>
        <li className={tabClass}>
          <button className={`nav-link rounded-0  w-100 h-100 ${buttonClass}`}
            id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="true">
            Versiones
          </button>
        </li>





      </React.Fragment>
    )

  }

  const tabVersions = () => {

    return (
      <React.Fragment>

        <div className={"col d-flex justify-content-end align-items-center"}>
          <Button onClick={() => {
            modalVersion.current.toggle()
          }} color={"secondary"}><i className={"fa fa-plus"} /> Agregar version</Button>


        </div>


        {plan.versions.map((v: any, index: any) => <Card className={"elevated mt-2"} bodyClassName={"p-0"}>
          <div className={"bg-light p-2 px-3 fs-3 text-gray fw-normal border-bottom mb-2"} style={{ borderTopRightRadius: "0.25rem", borderTopLeftRadius: "0.25rem" }}>
            {v.friendly_name} ({v.name})
          </div>


          <div className={"container mb-2"}>

            <div className={"col d-flex  align-items-center"}>



              <Button className={"me-2"} onClick={() => {
                uploadType = "menu"
                plan_version_id = v.id
                modalUpload.current.toggle()
              }} color={"secondary"}><i className={"fa fa-plus"} /> Agregar menu</Button>

              <Button onClick={() => {
                uploadType = "routines"
                plan_version_id = v.id
                modalUpload.current.toggle()
              }} color={"secondary"}><i className={"fa fa-plus"} /> Agregar rutina</Button>


            </div>
            <div className={"row "}>
              <div className="col-6">
                <div className={"fs-4"}>Menus</div>
                {v && v.menus && v.menus.length > 0 ?
                  <div>
                    <ol className="list-group mt-2">
                      {v.menus.sort((a: any, b: any) => a.display_order - b.display_order).map((r: any, index: any) =>

                        <li id={index} className="list-group-item d-flex g-2 justify-content-center align-items-center">

                          <div className={"me-1"}>
                            <Button onClick={() => {

                              if (index > 0) {
                                let resources = plan.resources
                                let temp = resources[index - 1].display_order
                                resources[index - 1].display_order = r.display_order
                                resources[index].display_order = temp
                                plan.resources = resources
                                setPlan(JSON.parse(JSON.stringify(plan)))
                              }

                            }} color="light"><i className={"fas fa-arrow-up"} /></Button>
                          </div>

                          <div className={"me-2 pe-2 border-end"}>
                            <Button onClick={() => {

                              if (index < plan.resources.length - 1) {
                                let resources = plan.resources
                                let temp = resources[index + 1].display_order
                                resources[index + 1].display_order = r.display_order
                                resources[index].display_order = temp
                                plan.resources = resources
                                setPlan(JSON.parse(JSON.stringify(plan)))
                              }

                            }} color="light"><i className={"fas fa-arrow-down"} /></Button>
                          </div>

                          <div>
                            <Button
                              onClickConfirmationPopoverOk={() => {

                                setLoading(true)
                                API.delete(`/plan/delete/${id}/docs`,
                                  () => {
                                    getData()
                                    Toast.add({ message: "Archivo eliminado", type: "primary" })
                                  },
                                  () => {
                                    setLoading(false)
                                    Toast.add({ message: "Error", type: "danger" })
                                  })

                              }}
                              confirmationPopoverHeader={`¿Estas seguro que quieres eliminar este archivo?`}
                              confirmationPopoverOkText={`Sí, estoy seguro`}
                              confirmationPopover

                              color="danger"><i className={"fas fa-trash"} /></Button>
                          </div>

                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{r.friendly_name}</div>
                            {r.name}
                          </div>
                          <a href={r.url} target="_blank" className="selectable"><i className={"fas fa-external-link-alt"} /></a>
                        </li>



                      )}

                    </ol>
                    <div className={"col d-flex justify-content-center align-items-center mt-2"}>
                      <Button onClick={() => {
                        let data = {
                          data: plan.resources.map((p: any) => {
                            return { id: p.id, display_order: p.display_order }
                          })
                        }

                        setLoading(true)
                        API.put(`/plan/${id}/docs/reorder`,
                          data, () => { getData() }, () => { })


                      }} color={"secondary"}>Actualizar orden de documentos</Button>
                    </div>

                  </div>
                  :
                  <div className={"w-100 mt-2 border d-flex justify-content-center align-items-center bg-light rounded p-4"} style={{ height: 150 }}>
                    NO HAY MENU
                  </div>
                }


              </div>
              <div className="col-6">
                <div className={"fs-4"}>Rutinas</div>
                {v && v.routines && v.routines.length > 0 ?
                  <div>
                    <ol className="list-group mt-2">
                      {v.routines.sort((a: any, b: any) => a.display_order - b.display_order).map((r: any, index: any) =>

                        <li id={index} className="list-group-item d-flex g-2 justify-content-center align-items-center">

                          <div className={"me-1"}>
                            <Button onClick={() => {

                              if (index > 0) {
                                let resources = plan.resources
                                let temp = resources[index - 1].display_order
                                resources[index - 1].display_order = r.display_order
                                resources[index].display_order = temp
                                plan.resources = resources
                                setPlan(JSON.parse(JSON.stringify(plan)))
                              }

                            }} color="light"><i className={"fas fa-arrow-up"} /></Button>
                          </div>

                          <div className={"me-2 pe-2 border-end"}>
                            <Button onClick={() => {

                              if (index < plan.resources.length - 1) {
                                let resources = plan.resources
                                let temp = resources[index + 1].display_order
                                resources[index + 1].display_order = r.display_order
                                resources[index].display_order = temp
                                plan.resources = resources
                                setPlan(JSON.parse(JSON.stringify(plan)))
                              }

                            }} color="light"><i className={"fas fa-arrow-down"} /></Button>
                          </div>

                          <div>
                            <Button
                              onClickConfirmationPopoverOk={() => {

                                setLoading(true)
                                API.delete(`/plan/delete/${id}/docs`,
                                  () => {
                                    getData()
                                    Toast.add({ message: "Archivo eliminado", type: "primary" })
                                  },
                                  () => {
                                    setLoading(false)
                                    Toast.add({ message: "Error", type: "danger" })
                                  })

                              }}
                              confirmationPopoverHeader={`¿Estas seguro que quieres eliminar este archivo?`}
                              confirmationPopoverOkText={`Sí, estoy seguro`}
                              confirmationPopover

                              color="danger"><i className={"fas fa-trash"} /></Button>
                          </div>

                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{r.friendly_name}</div>
                            {r.name}
                          </div>
                          <a href={r.url} target="_blank" className="selectable"><i className={"fas fa-external-link-alt"} /></a>
                        </li>



                      )}

                    </ol>
                    <div className={"col d-flex justify-content-center align-items-center mt-2"}>
                      <Button onClick={() => {
                        let data = {
                          data: plan.resources.map((p: any) => {
                            return { id: p.id, display_order: p.display_order }
                          })
                        }

                        setLoading(true)
                        API.put(`/plan/${id}/docs/reorder`,
                          data, () => { getData() }, () => { })


                      }} color={"secondary"}>Actualizar orden de documentos</Button>
                    </div>

                  </div>
                  :
                  <div className={"w-100 mt-2 border d-flex justify-content-center align-items-center bg-light rounded p-4"} style={{ height: 150 }}>
                    NO HAY RUTINAS
                  </div>
                }
              </div>
            </div>
          </div>



        </Card>)}
      </React.Fragment>)

  }

  const tabDocs = () => {

    return (
      <React.Fragment>
        <Card className={"elevated"} bodyClassName={"p-0"}>
          <div className={"bg-light p-2 px-3 fs-6 text-gray fw-normal border-bottom mb-2"} style={{ borderTopRightRadius: "0.25rem", borderTopLeftRadius: "0.25rem" }}>
            Documentos generales
          </div>

          <div className={"container mb-2"}>
            <div className={"col d-flex justify-content-end align-items-center"}>
              <Button onClick={() => {
                uploadType = "docs"
                modalUpload.current.toggle()
              }} color={"secondary"}><i className={"fa fa-plus"} /> Agregar documento</Button>


            </div>


            {plan && plan.resources && plan.resources.length > 0 ?
              <div>
                <ol className="list-group mt-2">
                  {plan.resources.sort((a: any, b: any) => a.display_order - b.display_order).map((r: any, index: any) =>

                    <li id={index} className="list-group-item d-flex g-2 justify-content-center align-items-center">

                      <div className={"me-1"}>
                        <Button onClick={() => {

                          if (index > 0) {
                            let resources = plan.resources
                            let temp = resources[index - 1].display_order
                            resources[index - 1].display_order = r.display_order
                            resources[index].display_order = temp
                            plan.resources = resources
                            setPlan(JSON.parse(JSON.stringify(plan)))
                          }

                        }} color="light"><i className={"fas fa-arrow-up"} /></Button>
                      </div>

                      <div className={"me-2 pe-2 border-end"}>
                        <Button onClick={() => {

                          if (index < plan.resources.length - 1) {
                            let resources = plan.resources
                            let temp = resources[index + 1].display_order
                            resources[index + 1].display_order = r.display_order
                            resources[index].display_order = temp
                            plan.resources = resources
                            setPlan(JSON.parse(JSON.stringify(plan)))
                          }

                        }} color="light"><i className={"fas fa-arrow-down"} /></Button>
                      </div>

                      <div>
                        <Button
                          onClickConfirmationPopoverOk={() => {

                            setLoading(true)
                            API.delete(`/plan/delete/${id}/docs`,
                              () => {
                                getData()
                                Toast.add({ message: "Archivo eliminado", type: "primary" })
                              },
                              () => {
                                setLoading(false)
                                Toast.add({ message: "Error", type: "danger" })
                              })

                          }}
                          confirmationPopoverHeader={`¿Estas seguro que quieres eliminar este archivo?`}
                          confirmationPopoverOkText={`Sí, estoy seguro`}
                          confirmationPopover

                          color="danger"><i className={"fas fa-trash"} /></Button>
                      </div>

                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{r.friendly_name}</div>
                        {r.name}
                      </div>
                      <a href={r.url} target="_blank" className="selectable"><i className={"fas fa-external-link-alt"} /> Ver documento</a>
                    </li>



                  )}

                </ol>
                <div className={"col d-flex justify-content-center align-items-center mt-2"}>
                  <Button onClick={() => {
                    let data = {
                      data: plan.resources.map((p: any) => {
                        return { id: p.id, display_order: p.display_order }
                      })
                    }

                    setLoading(true)
                    API.put(`/plan/${id}/docs/reorder`,
                      data, () => { getData() }, () => { })


                  }} color={"secondary"}>Actuaizar orden de documentos</Button>
                </div>

              </div>
              :
              <div className={"w-100 mt-2 border d-flex justify-content-center align-items-center bg-light rounded p-4"} style={{ height: 150 }}>
                NO HAY DOCUMENTOS
              </div>
            }

          </div>



        </Card>
      </React.Fragment>)

  }

  const tabProduct = () => {
    return (
      <React.Fragment>


        <Card className={"elevated"} bodyClassName={"p-0"}>
          <div className={"bg-light p-2 px-3 fs-6 text-dark fw-normal border-bottom mb-2"} style={{ borderTopRightRadius: "0.25rem", borderTopLeftRadius: "0.25rem" }}>
            Información general
          </div>
          <div className="p-3">
            <Popover
              title={<div>Liga directa al producto</div>}
              content={<div>Este campo se genera automaticamente cuando se crea por primera vez el producto. Este valor no se puede cambiar para crear una liga nueva es necesario crear un producto nuevo.</div>}>
              <span>Liga al producto en la tienda <i className="fas fa-info-circle" /></span>
            </Popover>

            <div className={"flex-fill d-flex  align-items-center"}>

              <Button onClick={() => {
                copyStringToClipboard(url)
              }} className={"ms-0"} color={"link"}><i className={"fas fa-copy"} /></Button>

              <a href={url} target={"_blank"} className={"ms-2 me-2"}><span id={"hrefPage"}>{url}</span><i className={"fas fa-external-link-alt"} /></a>


            </div>
            <hr />
          </div>


          <Form ref={formProduct} onSubmit={updateGeneralInfo} className="p-3">




            <Input className={"col-12 mt-1"} required name={"title"} placeholder={"Nombre"} />
            <RichTextEditor className={"col-12 mt-4"} required name={"body_html"} label={"Descripción (visible en la tienda)"} />

            <div className="row">
              <Input className={"col-6 mt-1"} type="number" required name={"price"} placeholder={"Precio"} />

              <Input required requiredmessage="Este campo es requerido. Si no hay un precio de comparación puedes asignar 0." className={"col-6 mt-1"} type="number" name={"compare_at_price"} placeholder={"Precio de comparación"} />

            </div>
            <hr />
            <p>Puedes modificar el estado del producto para controlar la visibilidad:</p>
            <ul>
              <li>Activo: El producto esta disponible para visualizar y comprar en la tienda.</li>
              <li>Archivado: El producto esta ya no se encuentra visible ni disponible en la tienda</li>
              <li>Borrador: El producto se encuentra en edición, no estará visible en la tienda.</li>
            </ul>

            <Select options={[{ value: "active", label: "Activo" }, { value: "archived", label: "Archivado" }, { value: "draft", label: "Borrador" }]} className={"col-12 mt-4"} required name={"status"} label={"Estado del producto"} />
            <Select options={[{value:"deny", label:"Sí"}, {value:"continue", label:"No"}]} className={"col-12 mt-4"} required name={"inventory"} label={"¿Producto agotado?"}/>

            <div className="d-flex justify-content-end mt-2">

              <Button color="primary" type="submit"><i className="fas fa-check" /> Actualizar</Button>
            </div>
          </Form>
        </Card>


      </React.Fragment>
    )
  }

  const tabInfo = () => {
    return (
      <React.Fragment>


        <Card className={"elevated"} bodyClassName={"p-0"}>
          <div className={"bg-light p-2 px-3 fs-6 text-dark fw-normal border-bottom mb-2"} style={{ borderTopRightRadius: "0.25rem", borderTopLeftRadius: "0.25rem" }}>
            Información del plan
          </div>

          <Form onSubmit={updatePlanInfo} ref={form} className="p-3">

            <Select className={"col-12 mt-2 "} onChange={(value) => setType(value)} options={[...planTypes.map((pt: any) => { return { value: pt.id, label: pt.name } }), { value: -1, label: "Otro" }]} required name={"plan_type_id"}
              placeholder={"Tipo de plan/reto"} label={"Tipo de plan/reto"} />

            {type == -1 ? <div className={"row"}>
              <Input className={"col-12 mt-2"} required name={"name_plan"} placeholder={"Nombre del nuevo plan/reto"} />

            </div> : null}


            <Input className={"col-12 mt-4"} required type={"color"} name={"color"} placeholder={"Color principal"} />


            <div className={"row"}>
              <RichTextEditor className="mt-3" name="description" label="Descripción (visible en Zona Fit)" />
            </div>




            <Button type={"submit"} className={"mt-3"} color="primary" block>Actualizar</Button>

          </Form>
        </Card>





      </React.Fragment>
    )
  }


  return (
    <React.Fragment>
      {loading ? <Loader message={"Cargando..."} /> : null}

      <Modal ref={modalImage}
        title={<React.Fragment><i className={"fa fa-image"} /> Actualizar imagen</React.Fragment>}

        buttonCancel={{ children: "Cancelar", color: "danger" }}
        buttonOk={{ children: <div><i className={"fas fa-cloud-upload-alt"} />{" "}Actualizar</div>, onClick: () => formImage.current?.submit() }}>
        <Form onSubmit={uploadPlanImage} ref={formImage}>
          <Upload name={"file"} required />

        </Form>
      </Modal>


      <Modal ref={modalUpload}
        title={<React.Fragment><i className={"fa fa-image"} /> Agregar documento</React.Fragment>}

        buttonCancel={{ children: "Cancelar", color: "danger" }}
        buttonOk={{ children: <div><i className={"fas fa-cloud-upload-alt"} />{" "}Actualizar</div>, onClick: () => formUpload.current?.submit() }}>
        <Form onSubmit={uploadFile} ref={formUpload}>

          <Input name={"name"} required placeholder={"Nombre"} />
          <Input className={"mt-2"} name={"friendly_name"} required placeholder={"Nombre público"} />
          <div className={"mt-2"}><Upload name={"file"} required /></div>

        </Form>
      </Modal>

      <Modal ref={modalVersion}
        title={<React.Fragment> Agregar versión</React.Fragment>}

        buttonCancel={{ children: "Cancelar", color: "danger" }}
        buttonOk={{ children: <div><i className={"fas fa-check"} />{" "}Agregar</div>, onClick: () => formVersion.current?.submit() }}>
        <Form onSubmit={addVersion} ref={formVersion}>

          <Input name={"name"} required placeholder={"Nombre"} />
          <Input className={"mt-2"} name={"friendly_name"} required placeholder={"Nombre público"} />






        </Form>
      </Modal>


      <div className={"container-fluid"}>


        <div className={"row"}>
          <div className={"col col-4 d-none d-lg-block"}>



            <Card className={"elevated"} bodyClassName={"p-0"}>
              <div className="p-0 ">

                {profileCard()}
              </div>
            </Card>

            <Card className={"elevated mt-2"} bodyClassName={"p-0 "}>

              <div className={"bg-light p-2 px-3 fs-6 fw-bold border-bottom"} style={{ borderTopRightRadius: "0.25rem", borderTopLeftRadius: "0.25rem" }}>
                Menu
              </div>
              <ul className="nav nav-pills  flex-column">

                {tabsSelect("nav-item", "text-start")}
              </ul>
            </Card>

            <Card className={"elevated mt-3"} bodyClassName={"p-0 px-0"}>
              <div className={`bg-danger p-2 px-3 text-white fs-6 fw-normal border-bottom`} style={{ borderTopRightRadius: "0.25rem", borderTopLeftRadius: "0.25rem" }}>
                <i className={"fas fa-exclamation-triangle"} />{" "}¿Quieres eliminar este plan?
              </div>

              <div className={"p-3 px-4"}>
                <p className="lead fs-6">
                  Los planes solo se pueden eliminar si no hay ningun usuario que lo haya adquirido. Si quieres eliminar el plan y tiene compras tienes que eliminar las compras.
                </p>
                <Button onClickConfirmationPopoverOk={deletePlan} confirmationPopoverHeader={`¿Estas seguro que  eliminar este plan?`} confirmationPopoverOkText={`Sí, estoy seguro`} color={`outline-danger`} confirmationPopover className={"mt-2"} block >
                  Eliminar plan</Button>

              </div>

            </Card>


          </div>
          <div className={"col col-12 col-lg-8"}>


            <div className={"d-lg-none"}>


              <Card className={"elevated mb-2"} bodyClassName={"p-0 "}>

                <ul className="nav nav-pills nav-justified">
                  {tabsSelect("nav-item nav-item-horizontal")}
                </ul>
              </Card>


              <Card className={"elevated mb-3"} bodyClassName={"p-0"}>






                {profileCard()}

              </Card>
            </div>

            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade " id="profile" role="tabpanel" aria-labelledby="profile-tab">
                {tabInfo()}
              </div>
              <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                {tabVersions()}
              </div>
              <div className="tab-pane fade" id="buys" role="tabpanel" aria-labelledby="buys-tab">
                {tabDocs()}
              </div>
              <div className="tab-pane fade show active" id="product" role="tabpanel" aria-labelledby="product-tab">
                {tabProduct()}
              </div>
            </div>



          </div>
        </div>



      </div>

    </React.Fragment>)


}
