
import React, { FunctionComponent } from "react"
import { Header, Sidebar } from "../lfit-lib"
import logo from "./../assets/images/logo-vertical.png"
import logoSmall from "./../assets/images/logo-small.png"
import { useDispatch, useSelector } from "react-redux"
import { toggleToast } from "../store/actions"
import { ToastContainer } from "../lfit-lib/Components/Toast"


export type LayoutProps = {
}



export const Layout: FunctionComponent<LayoutProps> = (props) => {

    const toast = useSelector((state: any) => state.ui.toast)
    const bColor = useSelector((state: any) => state.ui.bannerColor)
    const dispatch = useDispatch()


    return (<div>



        <Sidebar navMenuLink={[
            // {to:"/home", label:"Home", icon:"fas fa-home"},
            // {
            //     to: "/orders", label: "Ordenes", icon: "fas fa-receipt", subMenu: [
            //         { to: "/orders/open", label: "Abiertas", icon: "fas fa-circle" },
            //         { to: "/orders/closed", label: "Cerradas", icon: "fas fa-minus" },
            //     ]
            // },
            { to: "/plans", label: "Mis Planes/retos", icon: "fas fa-clipboard-list" },
            { to: "/mi-cuenta", label: "Mi cuenta", icon: "fas fa-user" },
            { to: "/fitness-health", label: "Fitness & Health", icon: "fas fa-weight" },
            { to: "/compras", label: "Mis compras", icon: "fas fa-receipt" },

        ]}
            navMenuLinkBottom={[

                // { to: "/settings", label: "Configuraciones", icon: "fas fa-cog" }

            ]}
            sidebarId={"sidebarmenu"} logo={logo} />


        <main className={"d-flex flex-column vh-100 main-content-div"} style={{ flex: 1 }}>
            <div className={"header-bar-wrapper"}>
                <Header sidebarTarget={"sidebarmenu"} logo={logo} color={"white"} bgColor={bColor} title={"Panel de administración"} />


            </div>

            <div className={"flex-fill pt-2 position-relative"}>
                <div className={"container-fluid  "}>
                    <div className={`position-absolute top-0 start-0 bg-${bColor}`} style={{ height: 80, zIndex: -1, backgroundColor: bColor, width: "100%" }} />
                    {props.children}

                </div>


            </div>

            <div className={"text-center pb-1"}>Libremente Fit</div>

            <ToastContainer />




        </main>





    </div>)


}
