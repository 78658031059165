
import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import { Link, useHistory } from "react-router-dom"
import  Form  from "../lfit-lib/Forms/Form"

import  Input  from "../lfit-lib/Forms/Fields/Input"
import { Card, Table, Button } from "../lfit-lib"
import { Avatar } from "../lfit-lib/Components/Avatar"
import { Modal } from "../lfit-lib/Components/Modal"
import Select from "../lfit-lib/Forms/Fields/Select"
import { API } from "../utils/APIHandler"
import { Loader } from "../utils/Loader"
import { Toast } from "../lfit-lib/Components/Toast"
import { STATUS } from "../utils/Constants"
import { Popover } from "../lfit-lib/Components/Popover"






export const Customers : FunctionComponent = (props) =>{
  const [loading, setLoading] = useState(false)


  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState({prev:"", next:""})
  const [size, setSize] = useState(10)

  const history = useHistory()

  const modal = useRef<any>(null)
  const form = useRef <any> ()

  const loadCustomers = (from: string = "") => {
    setLoading(true)
    API.post(`/customer/`,{
      size:size,
      from: from
    },(response)=>{
      console.log(response)
      setUsers(response.data.items)
      setPagination({
        next: response.data.next_page_url,
        prev: response.data.previous_page_url
      })
      setLoading(false)

    

    },(response)=>{
      console.log(response)
      setLoading(false)

    })


  }

  useEffect(()=>{

    loadCustomers()

    
  },[])

  useEffect(()=>{

    loadCustomers()
    
    
  },[size])


  const createUser = (data:any, valid:boolean) =>{
    console.log(data, valid)
    if (valid){
      setLoading(true)
      API.post(`/customer/invite`,data,(response)=>{
        console.log(response)
   
        setLoading(false)
        if (response.status != 1){
          Toast.add({message:response.message, type:"danger"})
        }else{
          Toast.add({message:"Cuenta creada e invitación enviada.", type:"primary"})

        }
        modal.current.toggle()
        form.current.reset()
      },(response)=>{
        console.log(response)
        setLoading(false)
        Toast.add({message:response.message, type:"danger"})

      })
    }
 

  }



  return (
      <React.Fragment>
        {loading? <Loader message={"Cargando..."}/>:null}
        <Modal 
          title={<React.Fragment><i className={"fa fa-user-plus"}/> Invitar a un(a) cliente</React.Fragment>} 
          ref={modal} 
          buttonCancel={{children:"Cancelar", color:"danger"}}
          buttonOk={{children:<div><i className={"fas fa-paper-plane"}/> Enviar invitación</div>, onClick:()=>form.current.submit()}}
          >

              <Form ref={form} onSubmit={createUser}>
                <div className={"row"}>
                    <Input className={"col-12 mt-2"} required name={"email"} placeholder={"Correo electrónico"}/>

                  </div>
                  <div className={"row"}>
                     <Input className={"col-12 col-lg-6 mt-2"} required name={"first_name"} placeholder={"Nombre"}/>
                     <Input className={"col-12 col-lg-6 mt-2"} required name={"last_name"} placeholder={"Apellidos"}/>

                  </div>

                  <div className={"row "}>
                     <Input className={"col-12 mt-2"} name={"phone"} placeholder={"Teléfono"}/>
                     

                  </div>
                </Form>

               
        </Modal>
        <div className={"container-fluid"}>

 

<div className="row text-white align-items-center px-2 mb-2">
    <div className="col"><h2 className="mb-0"><i className={"fas fa-users"}/> Clientes</h2></div>

    <div className={"col d-flex justify-content-end align-items-center"}>
      <Button onClick={()=>modal.current.toggle()} color={"secondary"}><i className={"fa fa-user-plus"}/> Invitar</Button>
 

    </div>
</div>
       
          <Card className={"elevated"} bodyClassName={"px-0 py-0 pt-2"}>
           
            <Table 
              selectable
              withRowMenu
              onRowClick={(row, index)=>{
                console.log(row, index)
                history.push(`/customers/${row.shopify_id}`)
              }}
              columnsStyle={{cursor:"pointer"}}
              columns={[
                {label:"", key:"full_name", style:{width:"2rem"},
                  formatter:(value)=>{
                    return (
                    
                        <Avatar className={"mx-auto"} size={"sm"} initials={(value || "-").toUpperCase()[0]}/>

             
              
                    )
                  }
              }, 
              {label:"Name", key:"full_name"},
              {label:"Email", key:"email"},
              {label:"Miembro desde", key:"member_since"},
              {label:"Estatus", key:"state", style:{width:"2rem"},
              formatter:(value:any, row:any)=>{

                
            
                              
                return (
                   
                    <Popover title={<div>{STATUS[value].text}</div>}
                     content={<div>{STATUS[value].description}</div>}>


                  <div className={"d-flex align-items-center justify-content-center"}>

                    <span  style={{fontSize:11}} className={`w-100  p-1  badge bg-${STATUS[value].color}`}><div><i className={STATUS[value].iconClass}/> {STATUS[value].text}</div></span> 
            
                  </div>
                  
                  </Popover>
           
         
          
                )
              }
          },  
            ]} 
              data={users}
              footer={()=>
                <div className="d-flex  align-items-center px-2">
                <div className="col d-flex align-items-center">
                <div className={"me-2"}>Mostrando</div>
                  
                  <Select 
                    options={ [1,5,10,25,50].map((v)=>{return {value:v, label:v}})} 
                    onChange={(value)=>setSize(value)}
                    name={"profile"} 
                    defaultValue={size}
                    classSize={"sm"}
                    className={"bg-white"}/>
                  <div className={"ms-1"}>resultado(s)</div>
                </div>
  
                <div className={"col d-flex justify-content-end align-items-center"}>
  
  
                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                  <button aria-disabled="true" onClick={()=>loadCustomers(pagination.prev)} type="button" className={`btn btn-${pagination.prev?"primary":"light"}`} disabled={pagination.prev?false:true}><i className={"fas fa-arrow-left"}/></button>
                  <button aria-disabled="true" onClick={()=>loadCustomers(pagination.next)} type="button" className={`btn btn-${pagination.next?"primary":"light"}`} disabled={pagination.next?false:true}><i className={"fas fa-arrow-right"}/></button>
                </div>
  
                </div>
  
              </div>
              }
            />

          </Card>
    
        </div>
        
    </React.Fragment>)
  

}
