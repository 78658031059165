
import classNames from "classnames"
import React, {ForwardRefRenderFunction, forwardRef, PropsWithChildren, useState, useImperativeHandle} from "react"
import { Button } from "../.."
import { BaseField, defaultFieldProps, FieldProps } from "./BaseField"


export type UploadProps = {
    inline?: boolean,
    showImage?: boolean
}

export type UploadStateType = {
    value:any,
    fileValue: any,
    inputDisplay: boolean
    error:string | null
}

class Upload extends BaseField<UploadProps & React.HTMLProps<HTMLButtonElement>, UploadStateType> {

    constructor(props:any) {
        super(props);
        this.state = {
            value: null,
            fileValue: null, 
            inputDisplay: true,
            error:null
        }
       
      }

    onChange = (e:any) =>{
        let value  = e.target.files


        if (value && value.length > 0 &&  value[0] ){
            this.setState({fileValue:value[0]})
        }else{
            this.setState({fileValue:null})
        }
        

    }


    getValue = () =>{
        return this.state.fileValue  ? this.state.fileValue  : (this.state.value  ? this.state.value : null)
    }

    setValue = (value:any) => {
        console.log(value)
        this.setState({value})
        this.setState({inputDisplay: value ? false:true})
    }
   

    render(){
        const { placeholder, type, name, className , disabled, label, as, inline} = this.props
        const { error } = this.state


        let style : any = disabled ? {backgroundColor:"#f6f6f6"}:{}
        if (this.props.style){
            style = {...this.props.style,...style }
        }

        return (<>

{label ? <label htmlFor={this.id} className={`form-label`}>{label}</label>:null}

        {this.state.inputDisplay ?  
        
        (!inline ? 
                <div className="form-group files">

            <input  {...this.props as any} onChange={this.onChange} style={style}  type="file" className="form-control" multiple={false} id={this.id}/>
            {this.state.value ? <Button block color={"light"} onClick={()=>this.setState({inputDisplay:false})} >Mostrar foto</Button>:null}

            
                <div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
          </div>:

<div className="mb-3">
<input {...this.props as any} onChange={this.onChange} style={style}  type="file" className="form-control" multiple={false} id={this.id}/>

<div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
</div>):

<div style={{flexDirection:"row", display:"flex", marginBottom:20}}>
            <div style={{flex:1}}>
                
                {this.props.showImage ? 
                
                <div className="d-flex align-items-stretch  flex-column">
                    
                    <div  className={"d-flex align-self-stretch"}><img src={this.state.value} className={"img-fluid"} style={{flex:1}}/></div>
                <Button block color={"light"} onClick={()=>this.setState({inputDisplay:true})} ><i className={"fas fa-update"}/> Cambiar foto</Button>
                </div>
                :<a target={"_blank"} rel="noreferrer" href={this.state.value}><i className={"fas fa-image"}/> Ver foto</a>}
                
                </div>
            <div> {this.props.showImage ?null:<Button color={"light"} onClick={()=>this.setState({inputDisplay:true})} ><i className={"fas fa-update"}/> Cambiar foto</Button>}</div>
        </div>}
        
        </>)
        
    }
}


export default Upload