
import React, {FunctionComponent, useEffect, useState, useRef} from "react"
import { Button, Card } from "../lfit-lib"
import { Modal } from "../lfit-lib/Components/Modal"
import { API } from "../utils/APIHandler"
import  Form, {FormHandle}  from "../lfit-lib/Forms/Form"
import  Input  from "../lfit-lib/Forms/Fields/Input"
import TextArea from "../lfit-lib/Forms/Fields/TextArea"
import Select from "../lfit-lib/Forms/Fields/Select"
import { Link } from "react-router-dom"
import RichTextEditor from "../lfit-lib/Forms/Fields/RichTextEditor"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import { Loader } from "../utils/Loader"
import { PRODUCT_STATUS } from "../utils/Constants"
import { useHistory } from "react-router"




export const Products : FunctionComponent = (props) =>{

  const [page, setPage] = useState(1)
  const [type, setType] = useState(null)
  const [size, setSize] = useState(10)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const form = useRef <FormHandle> (null)
  const modal = useRef <any> ()
  const navigation = useHistory()

const getProducts = () => {
 setLoading(true)
 API.get(`/products`, (response)=>{
  console.log(response)
  //modal.current.toggle()
  setProducts(response.data.items)

  setLoading(false)

},(response)=>{
  console.log(response)
  //modal.current.toggle()

  setLoading(false)
})


  

}

  useEffect(()=>{
    getProducts()
 
  },[])

  const addProduct = (data:any, valid:boolean) => {

    if (valid){

    setLoading(true)
    API.upload(`/products`, {...data,price:0,compare_at_price:0,status:"draft",body_html:"" }, (response)=>{
      console.log(response)
      modal.current.toggle()
     

      setLoading(false)

      navigation.push(`/products/edit/${response.data.id}`)
   
    },(response)=>{
      console.log(response)
      //modal.current.toggle()

      setLoading(false)
    })
    }
   
  }



  return (
      <React.Fragment>
        {loading ? <Loader message={"Cargando..."}/> : null}

<Modal 
size="lg"
          title={<React.Fragment><i className={"fa fa-clipboard-list"}/> Nuevo producto</React.Fragment>} 
          ref={modal} 
          buttonCancel={{children:"Cancelar", color:"danger"}}
          buttonOk={{children:<div>Continuar{" "}<i className={"fas fa-chevron-right"}/></div>, onClick:()=>form.current?.submit()}}
          >

              <Form  ref={form} onSubmit={addProduct}>



                <Input className={"col-12 mt-1"} required name={"title"} placeholder={"Nombre del producto"}/>
            
                
                </Form>

               
        </Modal>
        <div className={"container-fluid"}>

 

          <div className="row text-white align-items-center px-2 mb-2">
              <div className="col"><h2 className="mb-0"><i className={"fas fa-boxes"}/> Productos</h2></div>

              <div className={"col d-flex justify-content-end align-items-center"}>
                <Button onClick={()=>modal.current.toggle()} color={"secondary"}><i className={"fa fa-plus"}/> Nuevo producto</Button>
           

              </div>
          </div>

          <div className={"row row-cols-1 row-cols-md-2 row-cols-xl-3"}>
            {products.map((product:any, index)=>{

              return (
                <div className="col  pb-2">
                     <div  className="card h-100 elevated p-0">


                     <div style={{fontSize:14}} className={`badge bg-${PRODUCT_STATUS[product.status].color} rounded-0 pt-2 pb-2 rounded-top`}><b><i className={PRODUCT_STATUS[product.status].iconClass}/>{" "}{PRODUCT_STATUS[product.status].text}</b></div>



                        {product.images && product.images.length > 0 ? <img src={product.images[0].src} height={300} style={{objectFit:"contain"}} className=" card-img-top border"/> : 

                          <div style={{height:300}} className={" border d-flex justify-content-center align-items-center bg-light card-img-top p-4 img-fluid"} >
                            NO HAY IMAGEN
                          </div>
                          }
                        <div className="card-body d-flex flex-column">
  

                      <h4 className="card-title">

                        
                     
                      {product.title}

                      </h4>


                      <p className="card-text">
                      ${product.price}
                      </p>
                    
                      <div className={"d-flex align-items-end h-100"}>
                   
                        {product.status == "active" ? <a className={"float-end w-100 me-2 text-decoration-none"}  target={"_blank"} href={`https://www.librementefit.mx/products/${product.handle}`}> <Button block  color={"outline-secondary"}><i className={"fas fa-eye"}/> Ver en tienda</Button></a>:null}

                        <Link className={"float-end w-100 text-decoration-none"} to={`/products/edit/${product.id}`}> <Button block  color={"primary"}><i className={"fas fa-pen"}/> Editar</Button></Link>
                      </div>


                
                    </div>

                 
                  </div>
                </div>
              )

            })}


          </div>
       


     
      

    
      </div>
        
    </React.Fragment>)
  

}
