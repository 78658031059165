import classNames from "classnames"
import React, {FunctionComponent, useEffect, useImperativeHandle, useRef} from "react"
import * as bootstrap from "bootstrap"
import { uniqueId } from "../../utils/Constants"
import { Button, ButtonProps } from ".."


type ModalProps = {
    size?: string,
    className?: string,
    bodyClassName?: string
    toggle?:boolean,
    title?:string  | JSX.Element,
    buttonOk?:ButtonProps,
    buttonCancel?: ButtonProps,
    hideFooter?:boolean,
    onOk?: () => void,

}

export type ModalHandler = {
  toggle: () => void,
}



const ModalWrapper : React.ForwardRefRenderFunction<ModalHandler, React.PropsWithChildren<ModalProps>>  = (props, ref) => {

    const {children, title, buttonOk,buttonCancel, size, className, hideFooter } = props


    const id = uniqueId("modal")


    useImperativeHandle(ref, () => ({
      toggle:()=>{
        var myModalEl :any = document.getElementById(id)
      var modal = bootstrap.Modal.getOrCreateInstance(myModalEl) 
      modal.toggle()
      }
      }));



    return (
<React.Fragment>
    
<div className={`modal fade`} id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">

      <div id="mod"  className={`modal-dialog modal-dialog-centered modal-${size} ${className}`}>

            
        <div className="modal-content">
          {title? <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>:null}
          <div className="modal-body">
            {children}
          </div>


          {hideFooter? null:<div className="modal-footer">
          
            <Button type="button" color="secondary" data-bs-dismiss="modal" {...buttonCancel}/>
            <Button type="button" color="primary" {...buttonOk}/>

          </div>}
        </div>
      </div>
      </div>
      </React.Fragment>
    )

}

export  const Modal = React.forwardRef(ModalWrapper)