import React, {createRef, FunctionComponent, useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, bootstrap, Button, Table } from "../lfit-lib"
import { Avatar } from "../lfit-lib/Components/Avatar"
import { Modal } from "../lfit-lib/Components/Modal"
import { Popover } from "../lfit-lib/Components/Popover"
import { Toast } from "../lfit-lib/Components/Toast"
import Input from "../lfit-lib/Forms/Fields/Input"
import Radio from "../lfit-lib/Forms/Fields/Radio"
import Select from "../lfit-lib/Forms/Fields/Select"
import Form from "../lfit-lib/Forms/Form"
import { toggleToast } from "../store/actions"
import { API } from "../utils/APIHandler"
import { getIMC, IMC, PRODUCT_STATUS, PROFILE_TYPES, STATUS } from "../utils/Constants"
import { Loader } from "../utils/Loader"
import Upload from "../lfit-lib/Forms/Fields/Upload"
import { Carousel } from "../lfit-lib/Components/Carousel"
import { PlanSelectorModal } from "../components/PlanSelector"



type CustomerParams = {
  id: string;
};


export const MisCompras : FunctionComponent = (props) =>{
  const userS = useSelector((state:any)=>state.user.user)
  console.log(userS)
  let id = userS.shopify_id
  const [loading, setLoading] = useState(false)

  const [disease, setDisease] = useState(false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [suplement, setSuplements] = useState(false)
  const [disabledFH, setDisabledFH] = useState(false)
  
  const [hf, setHF] = useState<any>([])
  const [user, setUser] = useState<any>({state:"disabled"})
  const form = useRef <any> ()
  const formFH = useRef <any> ()
  const dispatch = useDispatch()
  const modal = useRef<any>(null)
  const planSelector = useRef<any>(null)
  const popovers :any = {};
  const [planList, setPlanList] = useState<any>([])
  const [plans, setPlans] = useState([])

  const [orders, setOrders] = useState([])
  const [pagination, setPagination] = useState({prev:"", next:"",prev_cur:"", next_cur:""})


  const loadOrders = (from: string = "", back=false) => {
    setLoading(true)
    API.post(`/order/user/${id}`,{
      size:size,
      back:back,
      from: from,
      status:"any"
    },(response)=>{
      console.log(response)
      setOrders(response.data.items)
      setPagination({
        next_cur: response.data.next_page_url,
        prev_cur: response.data.previous_page_url,
        next: response.data.has_next,
        prev: response.data.has_prev
      })
      setLoading(false)

      


    },(response)=>{
      console.log(response)
      setLoading(false)

    })
 

  }

  const getUserPlanData = (user_id:any, plan_id:any, order_id:any, item_id:any) => {
    setLoading(true)
    API.get(`/customer/get/${user_id}`,(response)=>{
  
      setUser(response.data)

      setLoading(false)
      setTimeout(()=>{
        planSelector.current.setPlanId(plan_id, {order_id:order_id,item_id:item_id, user_id:user_id })
        planSelector.current.toggle()
      },100)


    

    },(response)=>{
      console.log(response)
      setLoading(false)

    })
  }


  
  useEffect(()=>{


    setLoading(true)

    API.get(`/customer/get/${id}`,(response)=>{
      //console.log(response)
      loadOrders()
      setUser(response.data)
      //console.log(form)
      form.current.fill(response.data)

    

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      new bootstrap.Popover(tooltipTriggerEl, {animation:false,html: true,})

    })

    },(response)=>{
      //console.log(response)
      // setLoading(false)
    })

   

    


    
  },[])




  





  const comprasTab = () =>{
    return (
      <React.Fragment>
      <Card  className={"elevated mb-3"} bodyClassName={"p-0"}>

<div className={"bg-light p-2 px-3 fs-6 text-gray fw-normal border-bottom mb-2"} style={{borderTopRightRadius:"0.25rem", borderTopLeftRadius:"0.25rem"}}>
Compras
</div>

<div className={"p-4 px-4 pt-0"}>
<Table 
              selectable={false}
              
           


              columnsStyle={{cursor:"pointer"}}


              columns={[
                {label:"", key:"full_name", className:"bg-white border border-light p-0", style:{width:"2rem"},
                formatter:(value, row)=>{
                  return (
                  
                    <div className=" btn  w-100 h-100" data-bs-toggle="collapse" data-bs-target={"#collapse"+row.id} role="button" aria-expanded="false" aria-controls="collapseExample">
                   <i className="fas fa-plus"/></div>
  
           
            
                  )
                }
            },
                {label:"#", key:"order_number",
                  formatter:(value, row)=>{
                    return (
                    
                        <a target={"_blank"} href={row.order_status_url}>#{value}</a>

            
              
                    )
                  }},

         

           

              {label:"Fecha", key:"created_at"},  

      
              {label:"Estatus de pago", key:"financial_status"},  

             
              {label:"Total", key:"total_price", formatter:(value, row)=>{
                return (
                
                   <> {"$"+value}</>
    
         
          
                )
              }},

            ]} 
              data={orders}
              headerClassName={"fs-bold text-dark "}

              footer={()=>
                <div className="d-flex  align-items-center px-2">
                {/* <div className="col d-flex align-items-center">
                <div className={"me-2"}>Mostrando</div>
                  
                  <Select 
                    options={ [1,5,10,25,50].map((v)=>{return {value:v, label:v}})} 
                    onChange={(value)=>setSize(value)}
                    name={"profile"} 
                    defaultValue={size}
                    classSize={"sm"}
                    className={"bg-white"}/>
                  <div className={"ms-1"}>resultado(s)</div>
                </div> */}
  
                <div className={"col d-flex justify-content-end align-items-center"}>
  
                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                  <button aria-disabled="true" onClick={()=>loadOrders(pagination.prev_cur, true)} type="button" className={`btn btn-${pagination.prev?"primary":"light"}`} disabled={pagination.prev?false:true}><i className={"fas fa-arrow-left"}/></button>
                  <button aria-disabled="true" onClick={()=>loadOrders(pagination.next_cur)} type="button" className={`btn btn-${pagination.next?"primary":"light"}`} disabled={pagination.next?false:true}><i className={"fas fa-arrow-right"}/></button>
                </div>
                </div>
  
              </div>
              }
            />


</div>

</Card>
    </React.Fragment>
    )
  }

  


  

  return (
      <React.Fragment>
        {loading? <Loader message={"Cargando..."}/>:null}



        <div className={"container"}>
       
{comprasTab()}
    
    
        </div>
        
    </React.Fragment>)
  

}
