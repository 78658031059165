import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { GET_PROFILE, LOGIN, setProfile } from "./actions"

import { get, post } from "../../utils/APIHandler"


function* getProfile( {payload: {callback, onError}}) {
    const response = yield call(get, "/auth/validate-token")
    callback(response)
}

function* login( {payload: { data, onSuccess, onError}}) {
  console.log(data)
  const response = yield call(post, "/auth/login",data)
  if(response.status == 1 && response.data && response.data.auth_token  && response.data.user  ){

    localStorage.setItem("authToken", response.data.auth_token);

    yield put(setProfile(response.data.user))

    onSuccess(response.data)
  }else{
    onError(response.message)
  }

}

function* userSaga() {
  yield takeEvery(GET_PROFILE, getProfile)
  yield takeEvery(LOGIN, login)
}
  
export default userSaga